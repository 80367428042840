import { useState } from 'react'
import BFTable from './small/BFTable'
import { COINS } from '../data/fakeData'
// import { useCryptosByMarketCap } from '../endpoints/index'
import { useCryptosByVolume } from '../endpoints/index'
import BFLoading from './small/BFLoading'
import { Link } from 'react-router-dom'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

export default function TwitterEnbeb() {
	const [isLoading, setLoading] = useState(false)

	const onLoad = () => {
		setLoading(true)
		{isLoading &&
			<div className="h-96 bg-white">
				<BFLoading heightAdjust="h-[45vh]" />
			</div>
		}
		setLoading(false)
	}
	return(
		//  <TwitterTimelineEmbed
		// 	sourceType="profile"
		// 	screenName="elonmusk"
		// 	tweetLimit= '10'
		// 	noScrollbar
		// 	options={{height: 986}}
		// 	onLoad={onLoad}
		// />
		// <coingecko-coin-converter-widget  coin-id="bitcoin" currency="usd" background-color="#ffffff" font-color="#4c4c4c" locale="en"></coingecko-coin-converter-widget>
		<div>
			<coingecko-coin-heatmap-widget  height="400" locale="en"></coingecko-coin-heatmap-widget>

			<coingecko-coin-market-ticker-list-widget  coin-id="bitcoin" currency="usd" locale="en"></coingecko-coin-market-ticker-list-widget>
	    </div>

	)
}
