import { Link, useParams } from 'react-router-dom'
import BFIcon from '../components/BFIcon'
import SocialIconsRow from '../components/small/SocialIconsRow'
import GraphCard from '../components/GraphCard'
import BFInfoTags from '../components/small/BFInfoTags'
import BFHoldingsTable from '../components/small/BFHoldingsTable'
import BFUpDownTag from '../components/small/BFUpDownTag'
import { useCryptoById } from '../endpoints/index'
import BFLoading from '../components/small/BFLoading'
import BFCryptoInfo from '../components/BFCryptoInfo'
import { baseUrl, favoriteIndex, useFavoriteIndexes, deleteIndex } from '../endpoints/index'
import { useSWRConfig } from 'swr'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BFImage from '../components/small/BFImage'
import FooterMobile from '../components/small/FooterMobile'
import { formatMoney, calculateReturn, calculateDrawdown } from '../helpers/index'
import BFPerformance from '../components/small/BFPerformance'

export default function CryptoPage({ isAuth }) {
	const params = useParams()
	const { data, isLoading, isError } = useCryptoById(params.id, isAuth)

	const navigate = useNavigate()

	const { mutate } = useSWRConfig()
	const { data: favoriteData, isLoading: favoriteIsLoading } = useFavoriteIndexes()

	const [loadingFavorites, setLoadingFavorites] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)

	const [totalReturn, setTotalReturn] = useState(0)
	const [drawdown, setDrawdown] = useState(0)
	const [fixedReturn, setFixedReturn] = useState(0)
	const [fixedDrawdown, setFixedDrawdown] = useState(0)

	const calcTotalReturn = (currentPrice,initialPrice) => {
		setTotalReturn(calculateReturn(currentPrice, initialPrice))
	}
	const calcDrawdown = (values) => {
		setDrawdown(calculateDrawdown(values))
	}

	useEffect(() => {
		if (data) {
			setFixedReturn(calculateReturn(data.index.value || data.index.price, data.index.initial_value))
			setFixedDrawdown(calculateDrawdown(data.index.daily_graph_data.prices || data.index.daily_graph_data.price || []))
		}
	})

	const [showMenu, setShowMenu] = useState(false)

	const compareRow = (e) => {
		e.stopPropagation()
		// navigate(`/compare?id=${params.id}`)
		navigate(`/compare?id=${params.id}&isAuth=${isAuth}`)
	}

	const favoriteRow = async (e) => {
		e.stopPropagation()
		if (localStorage.getItem('userId')) {
			setLoadingFavorites(true)
			const result = await favoriteIndex(params.id)
			await mutate(baseUrl('/get-favorited-indexes'))
			setLoadingFavorites(false)
			return
		}
	}


	const deleteRow = async () => {
		setLoadingDelete(true)
		const result = await deleteIndex(params.id)
		await mutate(baseUrl('/get-user-indexes'))
		setLoadingDelete(false)
		navigate('/indexes/my-indexes')
	}



	const PanelOne = ({ id, name, description, isAuth }) => (
		<div className='w-full h-full relative'>
			<div className="flex flex-row items-center justify-between">
				<div className='flex flex-row items-center gap-2'>
					<BFImage src={data.index.logo} alt={name} style="border-1 rounded-full p-1 bg-white w-16 h-16 object-cover shadow-lg shadow-main-shadow" />
					<h2 className="text-[18px] font-DM_Sans font-medium leading-normal tracking-normal text-main-black">{name}</h2>
				</div>

				{isAuth &&
				<div className="w-1/5 flex flex-row gap-2 items-end">
					<span onClick={() => editRow()}> <BFIcon iconName="edit" size="sm" color="#b7c3d1" /> </span>
					{loadingDelete ? <BFLoading isCenter={true} /> : <span className="mx-2" onClick={() => setShowMenu(!showMenu)}> <BFIcon iconName="delete" size="sm" color="#b7c3d1" /> </span>}
				</div>
				}
			</div>
			<div className="flex flex-row gap-3 justify-between w-full my-4">
				<button
					onClick={(e) => favoriteRow(e)}
					className="w-full flex flex-row gap-2 justify-center items-center shadow shadow-main-shadowBlue text-[15px] font-DM_Sans font-bold leading-normal tracking-normal rounded-lg bg-main-gColor hover:bg-main-buttonBlue text-white h-[38px]">
					{loadingFavorites ? <BFLoading isInline={true} />
						: favoriteData?.some(obj => obj.index._id.$oid === params.id) ? <BFIcon iconName="favorite" />
						: <BFIcon iconName="open-favorite" />
					} Favorite
				</button>
				<button
					onClick={(e) => compareRow(e)}
					className="w-full text-[15px] font-DM_Sans font-bold leading-normal tracking-normal shadow shadow-main-shadowBlue rounded-lg bg-main-lightGreen text-main-green h-[38px]">
						Compare
				</button>
			</div>

			{/* <div>
				<p className="text-[16px] font-DM_Sans font-normal leading-normal tracking-normal text-main-grayText mb-2">Share this index</p>
				<div className="flex flex-row justify-between cursor-pointer text-main-gray">
					<SocialIconsRow showCopyLink={true} />
				</div>
			</div> */}

			{/* <p className='text-[16px] font-DM_Sans font-medium leading-normal tracking-normal text-main-grayText'>{'Share this Index'}</p> */}

			<p className='text-[16px] font-DM_Sans font-normal leading-normal tracking-normal text-main-gray pt-2 my-4'>{description}</p>

			{/* {isAuth &&
				<div className="absolute bottom-2 w-1/5 flex flex-row gap-2 justify-between">
					<button onClick={() => editRow()} className="w-full text-gray-500 bg-gray-100 rounded px-6 py-2 font-bold text-sm">Edit</button>
					<button onClick={() => deleteRow()} className="w-full text-red-500 bg-red-100 rounded px-6 py-2 font-bold text-sm">
						{loadingDelete ? <BFLoading isCenter={true} /> : 'Delete'}
					</button>
				</div>
				} */}
			<BFPerformance data={data} totalReturn={totalReturn} drawdown={drawdown} fixedReturn={fixedReturn} fixedDrawdown={fixedDrawdown}/>
		</div>
	)

	if (isLoading) return <BFLoading />

	const editRow = async () => {
		navigate('/indexes/create-index', {
			state: {
				name: data.index.name,
				description: data.index.description,
				initialValue: data.index.initial_value,
				rebalancePeriod: data.index.rebalancing_interval,
				initialTimestamp: data.index.initial_timestamp,
				weightingMethod: data.index.weighting_method,
				selectedCryptos: data.index.cryptos,
				selectedCryptoIds: data.index.crypto_ids,
				logo: data.index.logo,
				previousId: data.index._id.$oid,
				mode: "Save"
			}
		})
	}
	let formattedHoldings = []
	if (data.rawStocks) {
		formattedHoldings  = data.rawStocks.map(stock => {
			return {
				...stock,
				holdingQuantity: data.index.holdings[stock.symbol],
				indexPrice: data.index.value
			}
		})
	}

	const UpTag = ({ value }) => (
		<span className="text-[30px] font-DM_Sans font-medium leading-normal tracking-normal text-main-green"><BFIcon iconName="up-right-arrow" /> {Math.abs(value).toFixed(2)}%</span>
	)

	const DownTag = ({ value }) => (
		<span className="text-[30px] font-DM_Sans font-medium leading-normal tracking-normal text-main-deepOrange"><BFIcon iconName="down-left-arrow" /> {Math.abs(value).toFixed(2)}%</span>
	)

	return (
		<div>
		<div className="grid grid-cols-1 xl:grid-cols-12 bg-main-lightGray pt-8 sm:pt-0">
			<div className="p-6 space-y-4 border-r bg-white hidden xl:block col-span-3">
				<PanelOne {...data.index} isAuth={isAuth} />
			</div>

			<div className="col-span-9 space-y-4 sm:p-4">
				<div className='block bg-main-white p-4 border-[1px] border-main-lightGrayBorder md:hidden'>
					<PanelOne {...data.index} isAuth={isAuth} />
				</div>
				<BFCryptoInfo data={data} singlePage={true} indexPage={true} calcTotalReturn={calcTotalReturn} calcDrawdown={calcDrawdown} totalReturn={totalReturn} drawdown={drawdown} fixedReturn={fixedReturn} fixedDrawdown={fixedDrawdown}/>
			</div>
			<div className='bg-white md:hidden'>
				<FooterMobile />
			</div>
		</div>

		<div className='hidden xl:block w-full border'>
			{(formattedHoldings.length > 0) &&
				<div className={`bg-white border-main-lightGrayBorder p-4 overflow-y-auto space-y-4`}>
					<h2 className="text-[22px] font-DM_Sans font-medium leading-normal tracking-normal text-main-black">Holdings
						<span className="bg-main-lightSkyBlue rounded py-1 px-2 ml-2  text-[13px] font-DM_Sans font-bold leading-normal tracking-normal text-main-gray">{data.rawStocks.length}</span>
					</h2>
					<BFHoldingsTable holdings={formattedHoldings} />
				</div>
			}
		</div>

		{showMenu &&
			<div className="relative z-[60]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
			  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
   			    <div className="fixed inset-0 z-[70] overflow-y-auto">
			      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
		           <div className="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
		             <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bg-main-white"/>
						<div className="p-4 space-y-6">
						  <div className='text-[38px] flex justify-center'>
							<BFIcon color="#b7c3d1" iconName="delete" />
						  </div>
							<p className='text-[18px] font-DM_Sans font-medium leading-normal tracking-normal flex justify-center text-center'>Are you sure you want to remove?</p>
					  	<div className="mt-auto flex flex-col gap-2">
						  <Link onClick={() => deleteRow()}>
							<button
								type="submit"
								className="w-full rounded-lg bg-main-gColor text-[15px] font-DM_Sans font-bold leading-normal tracking-normal shadow-sm shadow-main-shadowBlue text-white py-2"
							>
								{loadingDelete ?
									<div className="flex justify-center py-1">
										<BFLoading isSmall="true" />
									</div>
									: "Yes"
								}
							</button>
							</Link>
						  <Link onClick={() => setShowMenu(false)}>
							<button
								className="w-full rounded-lg bg-main-gColor bg-opacity-10 text-[15px] font-DM_Sans font-bold leading-normal tracking-normal text-main-gColor py-2"
							>
								No
							</button>
							</Link>
				    	</div>
					</div>
				  </div>
				</div>
			</div>
		  </div>
		  }
		</div>
	)
}
