import React from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from "react-error-boundary";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CookiePopup from './components/CookiePopup';
import Helmet from "react-helmet"
import bitLogo from './bitLogo.png'

const Error404 = () => {
	return (
		<div className="m-4 pt-3">
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="robots" content="noindex" />
				<title>Page Unavailable</title>
				<link rel="canonical" href="https://www.bitformance.com" />
				<link rel="stylesheet"  href="index.css"/>
				<meta
				  name="description"
				  content="Track, analyze, and create cryptocurrency indexes on one platform, for free. Choose from over 500+ cryptocurrencies and compare performance metrics with ease."
				/>
			</Helmet>
			<div class="error_container">
				<div class="error_heading">
					<img src={bitLogo} alt="" />
					<h1>Page Unavailable</h1>
					<h3>We are sorry, that page is currently unavailable.</h3>
				</div>
				<hr />
				<div class="error_section">
					<h3>We apologize for the inconvenience and will have the page back up momentarily.</h3>
					<p>Please <a href="https://www.bitformance.com">click here</a> to be redirected to the homepage.</p>
					<p>Otherwise, contact us at <strong>contact@bitformance.com</strong> for any questions or concerns.</p>
				</div>
			</div>
		</div>
	)
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CookiePopup/>
	<ErrorBoundary fallback={<Error404/>}>
    <App />
	</ErrorBoundary>
  </React.StrictMode>
);

const heatmapScript = document.createElement('script');
heatmapScript.async = true
heatmapScript.src = "https://widgets.coingecko.com/coingecko-coin-heatmap-widget.js";
document.head.appendChild(heatmapScript)

const converterScript = document.createElement('script');
converterScript.async = true
converterScript.src = "https://widgets.coingecko.com/coingecko-coin-converter-widget.js";
document.head.appendChild(converterScript)

const marqueeScript = document.createElement('script');
marqueeScript.async = true
marqueeScript.type = "text/javascript"
marqueeScript.src = "https://files.coinmarketcap.com/static/widget/coinMarquee.js";
document.head.appendChild(marqueeScript)

const tickerScript = document.createElement('script');
tickerScript.async = true
tickerScript.src = "https://widgets.coingecko.com/coingecko-coin-market-ticker-list-widget.js";
document.head.appendChild(tickerScript)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
