import { useParams, useNavigate } from 'react-router-dom'
import { useConfirmEmailByToken } from '../endpoints/index'
import BFLoading from '../components/small/BFLoading'

export default function EmailConfirmPage() {

	const params = useParams()
	const navigate = useNavigate()
	const { data, isLoading, isError } = useConfirmEmailByToken(params.token)

	if (isLoading || data === undefined) {
		return (
			<div className="max-w-lg mt-4 mx-auto">
				<BFLoading />
			</div>
		)
	}

	if (data) {
		let ec = true
		if (isError) {
			ec = false
		}
		navigate('/',  {
			state: {
				emailConfirmed: ec,
				showModalType: 'SIGN_IN'
			}
		})
		return
	}

	return(
		<div className="max-w-lg mt-4 mx-auto">

			{data !== undefined && data?.email_auth ?
				<p>Your email: {data.user.email} has been confirmed! Please sign in.</p>
				: <p>Something went wrong. Please try again.</p>
			}

		</div>
	)
}
