import BFGraph from './BFGraph'
import { useState, useEffect } from 'react'
import BFGraph2 from './BFGraph2'
import BFGraph1 from './BFGraph1'
import BFIcon from './BFIcon'
import { calculateDrawdown, calculateReturn } from "../helpers"
import BFLoading from './small/BFLoading'

export default function GraphCard({
	title,
	indexPrice,
	subtractWidth = 0,
	holdings,
	panelId = '',
	isOverlay = false,
	halfGraph = false,
	isCompare = false,
	createIndex = false,
	hideGraph,
	singlePage,
	calcTotalReturn,
	calcDrawdown,
	hook = function () { return { data: {}, isLoading: false, isError: false }},
	hide24h = false,
 	isIndicator = false }) {

	const { data, isLoading, isError } = hook()

	const [graphColor, setGraphColor] = useState('Solid Colored')
	const [graphInterval, setGraphInterval] = useState('All')

	const [finalDates, setFinalDates] = useState([])
	const [finalPrices, setFinalPrices] = useState([])

	const colorOptions = ['Price Colored', 'Solid Colored']
	// const dateOptions = ['24H', '7D', '1M', '3M', '6M', '1Y', '3Y', 'All']
	let dateOptions = ['24H', '7D', '1M', '3M', '6M', '1Y', 'All']
	if (hide24h == true) {
		dateOptions = ['7D', '1M', '3M', '6M', '1Y', 'All']
	}

	const [fullGraphData, setFullGraphData] = useState([])
	const [shownGraphData, setShownGraphData] = useState([])
	const [hourlyData, setHourlyData] = useState([])

	const [performanceReturn,setPerformanceReturn] = useState(0)
	const [performanceDrawdown,setPerformanceDrawdown] = useState(0)
	const [currentIndicatorValue,setCurrentIndicatorValue] = useState(0)
	const [indicatorValue30,setIndicatorValue30] = useState(0)
	const [indicatorValue90,setIndicatorValue90] = useState(0)

	const maxGraphLength = 3000

	const formatter = new Intl.NumberFormat('en-US');

	const UpTag = ({ value }) => (
		<span className="text-[30px] font-DM_Sans font-medium leading-normal tracking-normal text-main-green"><BFIcon iconName="up-right-arrow" /> {formatter.format(Math.abs(value).toFixed(2))}%</span>
	)

	const DownTag = ({ value }) => (
		<span className="text-[30px] font-DM_Sans font-medium leading-normal tracking-normal text-main-deepOrange"><BFIcon iconName="down-left-arrow" /> {formatter.format(Math.abs(value).toFixed(2))}%</span>
	)

	const IndicatorUpTag = ({ value }) => (
		<span className="text-[18px] font-DM_Sans font-medium leading-normal tracking-normal text-main-green">{formatter.format(Math.abs(value).toFixed(2))}</span>
	)

	const IndicatorDownTag = ({ value }) => (
		<span className="text-[18px] font-DM_Sans font-medium leading-normal tracking-normal text-main-deepOrange">{formatter.format(Math.abs(value).toFixed(2))}</span>
	)

	useEffect(() => {
	 	if (isOverlay && fullGraphData.length === 0) {
			loadOverlayData()
		}
		else if (data && data.index && fullGraphData.length === 0) {
			loadData()
		}

	}, [data])

	const loadOverlayData = (timeInterval = -1 * maxGraphLength) => {
		const dataOne = data[0]
		const dataTwo = data[1]

		let daily_graph_data_one = dataOne.data.daily_graph_data
		let daily_graph_data_two = dataTwo.data.daily_graph_data
		let fivemin_graph_data_one = dataOne.data.fivemin_graph_data
		let fivemin_graph_data_two = dataTwo.data.fivemin_graph_data

		if (dataOne.isCrypto) {
			daily_graph_data_one = dataOne.data.index.daily_graph_data
			fivemin_graph_data_one = dataOne.data.index.fivemin_graph_data
		}
		if (dataTwo.isCrypto) {
			daily_graph_data_two = dataTwo.data.index.daily_graph_data
			fivemin_graph_data_two = dataTwo.data.index.fivemin_graph_data
		}
		if (daily_graph_data_one.dates.length < daily_graph_data_two.dates.length && daily_graph_data_one.dates.length < -1 * timeInterval) {
			timeInterval = -1 * daily_graph_data_one.dates.length
		} else if (daily_graph_data_two.dates.length < daily_graph_data_one.dates.length && daily_graph_data_two.dates.length < -1 * timeInterval) {
			if (timeInterval < -1 * daily_graph_data_two.dates.length) {
				timeInterval = -1 * daily_graph_data_two.dates.length
			}
		}

		const { dates, prices, price } = daily_graph_data_one
		const { dates: datesTwo, prices: pricesTwo, price: priceTwo } = daily_graph_data_two
		const array = []

		if (!dataOne.isCrypto) {
			if (prices) {
				dataOne.data.index = { initial_value: prices.slice(timeInterval)[0] }
			} else {
				dataOne.data.index = { initial_value: price.slice(timeInterval)[0] }
			}
		}
		if (!dataTwo.isCrypto) {
			if (pricesTwo) {
				dataTwo.data.index = { initial_value: pricesTwo.slice(timeInterval)[0] }
			} else {
				dataTwo.data.index = { initial_value: priceTwo.slice(timeInterval)[0] }
			}

		}


		//Make date array lengths match
		let formatPrice = prices ? prices : price
		let formatPriceTwo = pricesTwo ? pricesTwo : priceTwo

		formatPrice = formatPrice.slice(timeInterval)
		formatPriceTwo = formatPriceTwo.slice(timeInterval)


		let initialIndexOne = formatPrice[0]
		let initialIndexTwo = formatPriceTwo[0]
		const properDateRange = dates.slice(timeInterval)

		let smallerLength = formatPrice.length > formatPriceTwo.length ? formatPriceTwo.length: formatPrice.length
		for (let i = 0; i < smallerLength; i++) {
			array.push({
				name: properDateRange[i],
				amt: ((formatPrice[i] - initialIndexOne) / initialIndexOne) * 100,
				amt2: ((formatPriceTwo[i] - initialIndexTwo) / initialIndexTwo) * 100
			})
		}
		setFullGraphData(array)
		setShownGraphData(array.slice(timeInterval))
		const hourly = []
		const { dates: hourlyDates, prices: hourlyPrices, price: hourlyPrice } = fivemin_graph_data_one
		const { dates: hourlyDatesTwo, prices: hourlyPricesTwo, price: hourlyPriceTwo } = fivemin_graph_data_two

		const initialValueOne = hourlyPrices ? hourlyPrices[0] : hourlyPrice[0]
		const initialValueTwo = hourlyPricesTwo ? hourlyPricesTwo[0] : hourlyPriceTwo[0]

		for (let z = 0; z < hourlyDates.length; z++) {
			const hourlyT = hourlyPrices ? hourlyPrices[z] : hourlyPrice[z]
			const hourlyTwo = hourlyPricesTwo ? hourlyPricesTwo[z] : hourlyPriceTwo[z]
			hourly.push({
				name: hourlyDates[z],
				amt: ((hourlyT - initialValueOne) / initialValueOne) * 100,
				amt2: ((hourlyTwo - initialValueTwo) / initialValueTwo) * 100,
			})
		}
		setHourlyData(hourly)

	}


	const loadData = () => {
		if (data.index.daily_graph_data) {
			const { dates, prices, price } = data.index.daily_graph_data
			const array = []
			for (let i = 0; i < dates.length; i++) {
				array.push({
					name: dates[i],
					amt: prices ? prices[i] : price[i]
				})
			}
			setFullGraphData(array.slice(-1 * maxGraphLength))
			setShownGraphData(array.slice(-1 * maxGraphLength))//1095
			setPerformanceReturn(calculateReturn(array.slice(-1*maxGraphLength)[array.slice(-1*maxGraphLength).length-1].amt, array.slice(-1*maxGraphLength)[0].amt))
			setPerformanceDrawdown(calculateDrawdown(array.slice(-1*maxGraphLength).map(({ amt }) => amt)))
			setCurrentIndicatorValue(prices ? prices[prices.length-1] : price[price.length-1])
			setIndicatorValue30(prices ? prices[prices.length-31] : price[price.length-31])
			setIndicatorValue90(prices ? prices[prices.length-91] : price[price.length-91])
			const hourly = []
			const { dates: hourlyDates, prices: hourlyPrices, price: hourlyPrice } = data.index.fivemin_graph_data
			for (let z = 0; z < hourlyDates.length; z++) {
				hourly.push({
					name: hourlyDates[z],
					amt: hourlyPrices ? hourlyPrices[z] : hourlyPrice[z]
				})
			}
			setHourlyData(hourly)
		}
	}

	useEffect(() => {
		if (fullGraphData.length) {
			displayGraphData(graphInterval)
		}
	}, [graphInterval])

	function displayGraphData(plotPeriod) {
	  switch (plotPeriod) {
	    case "24H":
			setShownGraphData(hourlyData)
			calcTotalReturn(hourlyData[hourlyData.length-1].amt, hourlyData[0].amt)
			setPerformanceReturn(calculateReturn(hourlyData[hourlyData.length-1].amt, hourlyData[0].amt))
			calcDrawdown(hourlyData.map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(hourlyData.map(({ amt }) => amt)))
			break;
	    case "7D":
	    	isOverlay ? loadOverlayData(-7) : setShownGraphData(fullGraphData.slice(-7)) //7
			calcTotalReturn(fullGraphData.slice(-7)[fullGraphData.slice(-7).length-1].amt, fullGraphData.slice(-7)[0].amt)
			setPerformanceReturn(calculateReturn(fullGraphData.slice(-7)[fullGraphData.slice(-7).length-1].amt, fullGraphData.slice(-7)[0].amt))
			calcDrawdown(fullGraphData.slice(-7).map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-7).map(({ amt }) => amt)))
	      break;
	    case "1M":
	    	isOverlay ? loadOverlayData(-30) : setShownGraphData(fullGraphData.slice(-30)) //30
			calcTotalReturn(fullGraphData.slice(-30)[fullGraphData.slice(-30).length-1].amt, fullGraphData.slice(-30)[0].amt)
			setPerformanceReturn(calculateReturn(fullGraphData.slice(-30)[fullGraphData.slice(-30).length-1].amt, fullGraphData.slice(-30)[0].amt))
			calcDrawdown(fullGraphData.slice(-30).map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-30).map(({ amt }) => amt)))
	      break;
	    case "3M":
	    	isOverlay ? loadOverlayData(-90) : setShownGraphData(fullGraphData.slice(-90)) //90
			calcTotalReturn(fullGraphData.slice(-90)[fullGraphData.slice(-90).length-1].amt, fullGraphData.slice(-90)[0].amt)
			setPerformanceReturn(calculateReturn(fullGraphData.slice(-90)[fullGraphData.slice(-90).length-1].amt, fullGraphData.slice(-90)[0].amt))
			calcDrawdown(fullGraphData.slice(-90).map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-90).map(({ amt }) => amt)))
	      break;
	    case "6M":
	    	isOverlay ? loadOverlayData(-180) : setShownGraphData(fullGraphData.slice(-180)) //180
			calcTotalReturn(fullGraphData.slice(-180)[fullGraphData.slice(-180).length-1].amt, fullGraphData.slice(-180)[0].amt)
			setPerformanceReturn(calculateReturn(fullGraphData.slice(-180)[fullGraphData.slice(-180).length-1].amt, fullGraphData.slice(-180)[0].amt))
			calcDrawdown(fullGraphData.slice(-180).map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-180).map(({ amt }) => amt)))
	     break;
	    case "1Y":
	    	isOverlay ? loadOverlayData(-365) : setShownGraphData(fullGraphData.slice(-365)) //365
			calcTotalReturn(fullGraphData.slice(-365)[fullGraphData.slice(-365).length-1].amt, fullGraphData.slice(-365)[0].amt)
			setPerformanceReturn(calculateReturn(fullGraphData.slice(-365)[fullGraphData.slice(-365).length-1].amt, fullGraphData.slice(-365)[0].amt))
			calcDrawdown(fullGraphData.slice(-365).map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-365).map(({ amt }) => amt)))
	      break;
	    case "3Y":
	    	isOverlay ? loadOverlayData(-1095) :setShownGraphData(fullGraphData.slice(-1095)) //1095
			calcTotalReturn(fullGraphData.slice(-1095)[fullGraphData.slice(-1095).length-1].amt, fullGraphData.slice(-1095)[0].amt)
			setPerformanceReturn(calculateReturn(fullGraphData.slice(-1095)[fullGraphData.slice(-1095).length-1].amt, fullGraphData.slice(-1095)[0].amt))
			calcDrawdown(fullGraphData.slice(-1095).map(({ amt }) => amt))
			setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-1095).map(({ amt }) => amt)))
	      break;
	    case "All":
	    		isOverlay ? loadOverlayData(-1 * maxGraphLength) :setShownGraphData(fullGraphData.slice(-1 * maxGraphLength)) ///3000
				calcTotalReturn(fullGraphData.slice(-1 * maxGraphLength)[fullGraphData.slice(-1 * maxGraphLength).length-1].amt, fullGraphData.slice(-1 * maxGraphLength)[0].amt)
				setPerformanceReturn(calculateReturn(fullGraphData.slice(-1 * maxGraphLength)[fullGraphData.slice(-1 * maxGraphLength).length-1].amt, fullGraphData.slice(-1 * maxGraphLength)[0].amt))
				calcDrawdown(fullGraphData.slice(-1 * maxGraphLength).map(({ amt }) => amt))
				setPerformanceDrawdown(calculateDrawdown(fullGraphData.slice(-1 * maxGraphLength).map(({ amt }) => amt)))
	    	  break;
	    default:
	      setShownGraphData([])
	  }
	}

	return(
		<div className="bg-white sm:rounded-xl">
			<h1 className="px-5 pt-6 text-[22.1px] font-DM_Sans font-medium leading-normal tracking-wide text-main-black">{title}</h1>
			{(isIndicator) &&
			<div className={singlePage ? 'hidden p-4 graphMetrics' : 'p-4 graphMetrics'}>
				<div className="pt-4 ml-3 mr-3 flex flex-col justify-between items-start">
					<p className="mb-2 font-DM_Sans font-light leading-normal tracking-wide text-main-black">
						This indicator shows the percentage of altcoins within a Top 200 Marketcap-Weighted Index that have a higher 90 day return than BTC. When this number is high, it indicates that altcoin season is in effect and that altcoins are more bullish than Bitcoin.
					</p>
					<div className="w-full flex flex-col justify-center md:flex-row md:space-x-6 border-t border-blue-600">
						<p className="mt-2 flex-col items-start ">
							<span className="text-[18px] font-DM_Sans font-medium leading-normal tracking-normal">Current Value: </span>
							{currentIndicatorValue >= 50 ? <IndicatorUpTag value={(currentIndicatorValue * 100).toFixed(0) || 0} /> : <IndicatorDownTag value={(currentIndicatorValue * 100).toFixed(0) || 0} /> }
						</p>
						<p className="mt-2 flex-col items-start ">
							<span className="text-[18px] font-DM_Sans font-medium leading-normal tracking-normal">30 day Value: </span>
							{indicatorValue30 >= 50 ? <IndicatorUpTag value={(indicatorValue30 * 100).toFixed(0) || 0} /> : <IndicatorDownTag value={(indicatorValue30 * 100).toFixed(0) || 0} /> }
						</p>
						<p className="mt-2 flex-col items-start ">
							<span className="text-[18px] font-DM_Sans font-medium leading-normal tracking-normal">90 day Value: </span>
							{indicatorValue90 >= 50 ? <IndicatorUpTag value={(indicatorValue90 * 100).toFixed(0) || 0} /> : <IndicatorDownTag value={(indicatorValue90 * 100).toFixed(0) || 0} /> }
						</p>
					</div>
				</div>
			</div>
			}
			<div className={`flex flex-col lg:flex-row items-center mb-6 px-5 pt-4
				${isCompare && 'xl:flex-col gap-1 2xl:flex-row'}`
			}>
				<div className="rounded-lg bg-main-lightGray px-1 py-1 text-main-gray">
					{colorOptions.map(option => (
						<button
							className={`text-[12px] px-3 py-2 font-DM_Sans font-normal leading-normal tracking-wide ${option === graphColor && 'bg-main-white py-1 px-4 rounded-lg shadow text-main-black text-[12px] font-bold'}`}
							key={option} onClick={() => setGraphColor(option)}
							disabled={((isOverlay && option === 'Price Colored') || (isIndicator && option === 'Price Colored'))}
						>
							{((isOverlay && option === 'Price Colored') || (isIndicator && option === 'Price Colored')) ? 'DISABLED' : option}
						</button>
					))}
				</div>
				<div className={`flex flex-row ml-0 lg:ml-auto mt-2 lg:mt-0 rounded-lg py-1 bg-main-lightGray items-center
					${isCompare && 'xl:ml-0 2xl:ml-auto'}
				`}>
					{dateOptions.map(option => (
						<button
							className={`text-[12px] text-main-gray font-DM_Sans font-normal leading-normal tracking-wide px-3 py-2
							${halfGraph ? 'px-3 py-2' : 'sm:px-7 py-2'}
							${option != "All" && 'border-r-[1px]'}
							${option != "24H" && 'border-l-[1px]'}
							${option === graphInterval && 'ml-2 mr-2 bg-main-white rounded-lg shadow text-main-black'}`}
							key={option}
							onClick={() => {setGraphInterval(option);}}
						>
							{option}
						</button>
					))}
				</div>
			</div>
			<div className='pb-4 text-[14px] font-DM_Sans font-normal leading-normal tracking-normal text-main-gray'>
				{
					panelId == '2' &&
					<BFGraph2
					subtractWidth={subtractWidth}
					indexPrice={indexPrice}
					halfGraph={halfGraph}
					isCompare={isCompare}
					createIndex={createIndex}
					data={shownGraphData}
					// panelId={panelId}
					showOverlay={isOverlay}
					showPriceColored={graphColor === 'Price Colored'}
					graphInterval={graphInterval}
				/>
				}
				{
					panelId == '1' &&
					<BFGraph1
					subtractWidth={subtractWidth}
					indexPrice={indexPrice}
					halfGraph={halfGraph}
					isCompare={isCompare}
					createIndex={createIndex}
					data={shownGraphData}
					// panelId={panelId}
					showOverlay={isOverlay}
					showPriceColored={graphColor === 'Price Colored'}
					graphInterval={graphInterval}
				/>
				}
				{
					panelId == '' &&
					<BFGraph
					subtractWidth={subtractWidth}
					indexPrice={indexPrice}
					halfGraph={halfGraph}
					isCompare={isCompare}
					createIndex={createIndex}
					data={shownGraphData}
					// panelId={panelId}
					showOverlay={isOverlay}
					showPriceColored={graphColor === 'Price Colored'}
					graphInterval={graphInterval}
					isIndicator={isIndicator}
				/>
				}
			</div>
			{(performanceReturn != 0 && !hideGraph && !isOverlay && !isIndicator) &&
			<div className={singlePage ? 'hidden p-4 graphMetrics' : 'p-4 graphMetrics'}>
				<h2 className="text-[22px] font-DM_Sans font-medium leading-normal tracking-normal text-main-black">Performance Metrics</h2>
				<div className="pt-4 flex flex-col justify-between items-start">
					<div className="w-full flex flex-col md:flex-row md:space-x-6">
						<p className="flex flex-col items-start ">
							{performanceDrawdown > 0 ? <UpTag value={performanceDrawdown || 0} /> : <DownTag value={performanceDrawdown || 0} /> }
							<span className="ml-2 mt-0.5 text-[16px] font-DM_Sans font-normal leading-normal tracking-normal text-main-black">Maximum Drawdown</span>
						</p>

						<div className='hidden md:block border-r-[1px] my-2 border-main-lightGrayBorder'/>

						<div className='border-t-[1px] my-2 border-main-lightGrayBorder md:hidden'/>

						<p className="flex flex-col items-start">
							{performanceReturn > 0 ? <UpTag value={performanceReturn || 0} /> : <DownTag value={performanceReturn || 0} /> }
							<span className="ml-2 mt-0.5 text-[16px] font-DM_Sans font-normal leading-normal tracking-normal text-main-black">Total Return</span>
						</p>
					</div>
				</div>
			</div>
			}
		</div>
	)
}
