import useSWR from 'swr'

const GET_FETCH_OPTIONS = () => {
	return {
		method: 'get',
		headers: {
			'Content-Type': 'application/json',
			"User-Agent": 'Mozilla/5.0'
		},
		mode: 'cors',
	}
}

const GET_FETCH_OPTIONS_AUTH = () => {
	return {
		method: 'get',
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
			'Content-Type': 'application/json',
			"User-Agent": 'Mozilla/5.0'
		},
		mode: 'cors',
	}
}

// const fetcher = (...args) => fetch(...args).then(res => res.json())
const fetcher = ( url, query = '' ) => fetch(
	`${url}${query}`,
	GET_FETCH_OPTIONS()
).then(res => {
	// if (res.status === 401) {
	// 	window.location.href = '/?sessionExpired=true'
	// }
	return res.json()
})
const fetcherAuth = ( url, query = '' ) => fetch(
	`${url}${query}`,
	GET_FETCH_OPTIONS_AUTH()
).then(res => {
	if (res.status === 401) {
		if (!window.location.href.includes('/?sessionExpired=true') && localStorage.getItem('username')) {
			localStorage.setItem('username', '')
			localStorage.setItem('firstName', '')
			localStorage.setItem('lastName', '')
			localStorage.setItem('picture', '')
			localStorage.setItem('accessToken', '')
			localStorage.setItem('userId', '')
			window.location.href = '/?sessionExpired=true'
		}
	}
	if (res.status === 403) {
		// if (!window.location.href.includes('/?sessionExpired=true') && localStorage.getItem('username')) {
		// 	localStorage.setItem('username', '')
		// 	localStorage.setItem('firstName', '')
		// 	localStorage.setItem('lastName', '')
		// 	localStorage.setItem('picture', '')
		// 	localStorage.setItem('accessToken', '')
		// 	localStorage.setItem('userId', '')
		window.location.href = '/'
		// }
	}
	return res.json()
})

export const baseUrl = (slug) => `${process.env.REACT_APP_API_URL}${slug}`

const POST_DATA_OPTIONS = (data) => {
	return {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	}
}

const POST_DATA_OPTIONS_FORM = (formData) => {
	return {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
		},
		body: formData
	}
}

//GET ********

export const useTopFifty = () => {
	const { data, error } = useSWR(baseUrl('/get-top50-index'), fetcher)
  return {
    data: data,
    isLoading: !error && !data,
    isError: error
  }
}

export const useAltcoinSeason = () => {
	let { data, error } = useSWR(baseUrl('/get-altcoin-season'), fetcher)
	let isloading = false
	if(!error && !data) {
		isloading = true
	}
	if (data) {
		data.data.index = {
			'daily_graph_data':data.data.daily_graph_data,
			'fivemin_graph_data':data.data.fivemin_graph_data
		}
	} else {
		data = {'data':{}}
		isloading = true
	}
	return {
		data: data.data,
		isLoading: isloading,
		isError: error
	}
}

export const useRecentIndexes = () => {
	const { data, error } = useSWR(baseUrl('/get-most-recent-indexes'), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const usePopularIndexes = () => {
	const { data, error } = useSWR(baseUrl('/get-most-popular-indexes'), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const usePerformers = () => {
	const { data, error } = useSWR(baseUrl('/performers'), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useAllIndexes = (sortField, sortOrder, indexType = "all") => {
	const { data, error } = useSWR(baseUrl(`/get-browsable-indexes?sortOrder=${sortOrder}&sortField=${sortField}&indexType=${indexType}`), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useBrowsableIndexes = (sortField, sortOrder, indexType = "bitformance") => {
	const { data, error } = useSWR(baseUrl(`/get-browsable-indexes?sortOrder=${sortOrder}&sortField=${sortField}&indexType=${indexType}`), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useSectorIndexes = (sortField, sortOrder) => {
	const { data, error } = useSWR(baseUrl(`/get-sector-indexes?sortOrder=${sortOrder}&sortField=${sortField}`), fetcher);
	if (data) {
		data.data.forEach( i => {
			i.constituents = i.crypto_ids.length
			i.subsectors.forEach(s => {
				s.index.constituents = s.index.crypto_ids.length
				s.index.name = s.index.industry
				if (s.index.weighting_method === 'equal_weight') {
					s.index.name = `EQW ${s.index.industry}`
				}

			})
		})
	}
	return {
	  data: data ? data.data : [],
	  taxonomyStats: data ? data.taxonomy_stats : null,
	  lastUpdated: data ? data.last_updated : "",
	  isLoading: !error && !data,
	  isError: error
	};
  };

export const useCryptosByMarketCap = (sortField, sortOrder, page = 0) => {
	const { data, error } = useSWR(baseUrl(`/get-coins-by-marketcap?limit=13&sortOrder=${sortOrder}&sortField=${sortField}&page=${page + 1}`), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useCryptosByVolume = (sortField, sortOrder, page = 0) => {
	const { data, error } = useSWR(baseUrl(`/get-coins-by-volume?limit=13&sortOrder=${sortOrder}&sortField=${sortField}&page=${page + 1}`), fetcher)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useCryptoById = (id, isAuth) => {
	const { data, error } = useSWR(
		baseUrl(`/get-index?id=${id}`),
		isAuth ? fetcherAuth : fetcher
	)
	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export const useCoinBySymbol = (symbol) => {
	const { data, error } = useSWR(
		baseUrl(`/get-coin-data?symbol=${symbol}&start=1451692800`),
		fetcher
	)

	if (data) {
		data.data.initial_value = data.data.daily_graph_data.price.slice(-3000)[0]
	}

	return {
		data: data ? data.data: null,
		isLoading: !error && !data,
		isError: error
	}

}

export const searchForIndex = async (searchText) => {
	const data = await fetcher(baseUrl(`/search-index?search=${searchText}`))
	return data
}

//GET AUTH **********
export const useMyIndexes = () => {
	const { data, error } = useSWR(baseUrl('/get-user-indexes'), fetcherAuth)
	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useFavoriteIndexes = () => {
	const { data, error } = useSWR(baseUrl('/get-favorited-indexes'), fetcherAuth)

	return {
		data: data ? data.data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const useProfile = () => {
	const { data, error } = useSWR(baseUrl('/profile'), fetcherAuth)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export const useAreMarketCapCoinsAvailable = () => {
	const { data, error } = useSWR(baseUrl('/get-marketcap-coins'), fetcherAuth)

	return {
		data: data,
		isLoading: !error && !data,
		isError: error
	}
}

export const getAllCoins = async () => {
	  const response = await fetch(baseUrl('/get-all-coins'))
	  const data = await response.json()
	  data.data.forEach(d => {
		  d.image_url = d.logo
	  })
	  return data
}

//POST **********

export const login = async (email, password) => {
	const response = await fetch(baseUrl('/login'), POST_DATA_OPTIONS({ email, password }))
	const data = await response.json()
	return data
}

export const registerAccount = async ({ email, password, username, first_name, last_name }) => {
	const response = await fetch(
		baseUrl('/register'),
		POST_DATA_OPTIONS({
			first_name,
			last_name,
			email,
			password,
			username
		})
	)
	const data = await response.json()
	return data
}

export const resetPassword = async (token, password, confirm_password) => {
	const response = await fetch(
		baseUrl(`/reset-link/${token}`),
		POST_DATA_OPTIONS({
			password,
			new_password: password,
			confirm_password
		})
	)
	const data = await response.json()
	return data
}

export const useConfirmEmailByToken = (token) => {
	const { data, error } = useSWR(baseUrl(`/email-confirmed/${token}`), fetcher)

	return {
		data: data ? data : [],
		isLoading: !error && !data,
		isError: error
	}
}

export const forgotPassword = async (email) => {
	const response = await fetch(
		baseUrl('/forgot-password'),
		POST_DATA_OPTIONS({
			email,
		})
	)
	const data = await response.json()
	return data
}

export const favoriteIndex = async (index_id) => {
	const response = await fetch(baseUrl('/favorites'), POST_DATA_OPTIONS({ index_id }))
	const data = await response.json()
	return data
}

export const generateChartPreview = async (name,weighting_method,description,initial_value,cryptos,custom_weights = {}, rebalancing_interval, initial_timestamp) => {
	const response = await fetch(
		baseUrl('/create-chart-preview'),
		POST_DATA_OPTIONS({
			// name: 'Example',
			name,
			weighting_method,
			// description: 'Test',
			description,
			initial_value,
			initial_timestamp,
			cryptos,
			custom_weights,
			rebalancing_interval,
			logo: ''
		})
	)
	const data = await response.json()
	return data
}

export const createIndex = async (name, weighting_method, description = '', initial_value, cryptos, rebalancing_interval, custom_weights = {}, logo = '',  initial_timestamp) => {
	const response = await fetch(
		baseUrl('/create-new-index'),
		POST_DATA_OPTIONS({
			name,
			weighting_method,
			description,
			initial_value,
			initial_timestamp,
			cryptos,
			custom_weights,
			rebalancing_interval,
			logo
		})
	)
	const data = await response.json()
	return data
}
export const editIndex = async (id, name, weighting_method, description = '', initial_value, cryptos, rebalancing_interval, custom_weights = {}, logo = '',  initial_timestamp) => {
	const response = await fetch(
		baseUrl('/edit-index'),
		POST_DATA_OPTIONS({
			id,
			name,
			weighting_method,
			description,
			initial_value,
			initial_timestamp,
			cryptos,
			custom_weights,
			rebalancing_interval,
			logo
		})
	)
	const data = await response.json()
	return data
}

export const getIndex = async (id, isAuth) => {
	const response = await fetch(
		baseUrl(`/get-index?id=${id}`),
		POST_DATA_OPTIONS({
			isAuth
		})
	)
	const data = await response.json()
	return data
}

export const deleteIndex = async (index_id) => {
	const response = await fetch(
		baseUrl('/remove-index'),
		POST_DATA_OPTIONS({
			index_id
		})
	)
	const data = await response.json()
	return data
}

export const uploadImage = async (imageFile, type = 'logo', indexId) => {
	const formData = new FormData();
	formData.append("file_obj", imageFile);
	formData.append("type", type);
	formData.append('id', indexId);
	const response = await fetch(baseUrl('/mime-files'), POST_DATA_OPTIONS_FORM(formData))
	const data = await response.json()
	return data
}

export const contactForm = async ({ username, subject, message, email }) => {
	const response = await fetch(
		baseUrl('/contact'),
		POST_DATA_OPTIONS({
			username,
			subject,
			message,
			email
		})
	)
	const data = await response.json()
	return data
}

export const contactFormBetaAccess = async ({ username,email,telegram,twitter,tier,message }) => {
	const response = await fetch(
		baseUrl('/contactAPIAccess'),
		POST_DATA_OPTIONS({
			username,
			email,
			telegram,
			twitter,
			tier,
			message
		})
	)
	const data = await response.json()
	return data
}
export const updateProfile = async (profile) => {
	const response = await fetch(baseUrl('/update-profile'), POST_DATA_OPTIONS({
		...profile
	}))
	const data = await response.json()
	return data
}
export const useApiInfo = () => {
	const { data, error } = useSWR(baseUrl('/get-user-api-info'), fetcherAuth)
	console.log(data)
	if (data) {
		if (data.data) {
			data.data.forEach(d => {
				d.key = d['api-key']
			})
		} else {
			data.data = []
		}
	}
	return {
		data: data ? data.data : [],
		isValid: data ? true : false
	}
}
export const getNewApiKeys = async (tier, is_new_key) => {
	const response = await fetch(baseUrl('/regenerate-api-key'),POST_DATA_OPTIONS({tier,is_new_key}))
	const data = await response.json()
	return data
}
