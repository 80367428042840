export default function PrivacyPolicyPage() {
	return (
		<div className="m-4 pt-3">
		<h1 className="priv_pol_h1"><strong><span className="priv_pol_h1_span">Privacy and Cookie Policy</span></strong></h1>
		<p className="priv_pol_p"><span className="priv_pol_p_span">Your privacy is important to us, and we greatly emphasize data security. We make sure we have appropriate security measures in place to prevent accidental loss or unauthorized access to your personal data.</span></p>
		<p className="priv_pol_p"><span className="priv_pol_p_span">Please read this Privacy and Cookie Policy carefully as it explains our practices regarding your personal data and how we will treat it, and the basis on which any personal data will be processed by us.</span></p>
		<p className="priv_pol_p"><span className="priv_pol_p_span">References in this Privacy and Cookies Policy and on our website to &ldquo;we&rdquo;, &ldquo;our&rdquo; or &ldquo;us&rdquo; are references to Bitformance.com. &quot;You&quot; and &quot;your&quot; pertain to each individual interacting with us, utilizing our website, or engaging with the products and/or services we offer.</span></p>
		<h2 className="priv_pol_h2"><strong><span className="priv_pol_h2_span">1. Information we collect and process about you</span></strong></h2>
		<p className="priv_pol_p"><span className="priv_pol_p_span">1.1 We may collect and process the following personal data about you:</span></p>
		<div className="priv_pol_div">
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span">1.1.1 Information you give to us. You may give us information about you when you register on Bitformance.com or email or otherwise. This also includes information you provide when you participate in surveys and user forums, make any submissions to us or tell us about your projects and/or how you use our data, and when you give us any feedback or report a problem with our website. The information you give us may include your name, email address, phone number, username, password, job title, and company information (including web links and addresses), and any other details you choose to provide to us when using any interactive aspects of our websites, such as when making any submissions to us or when telling us about your projects and how you use our data.</span></p>
			<p className="priv_pol_p2"><span className="priv_pol_p_span2">1.1.2 Information we collect about you when you communicate with us by phone, email, post, or otherwise, and when you use our products and services. We collect information such as the specifics of your contact with us, your responses to our communications, and insights into how and when you utilize our products and services.</span></p>
			<p className="priv_pol_p2"><span className="priv_pol_p_span2">&nbsp;</span></p>
			<p className="priv_pol_p2"><span className="priv_pol_p_span2">1.1.3 Information we collect about you if you use our website or interact with us over the internet, including via social media. Each time you visit our website or interact with us we may automatically collect the following information: (a) technical information, including the Internet protocol (IP) address used to connect your device to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform; (b) information about your visit, including the full Uniform Resource Locators (URL); products you viewed or searched for; length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs).</span></p>
			<p className="priv_pol_div_p"><span className="priv_pol_p_span2">1.1.4 Information we collect about you from publicly available sources. This may include information available from social media (depending on your settings and the applicable privacy policies).</span></p>
			<p className="priv_pol_div_p"><span className="priv_pol_p_span2">1.1.5 Information we receive from other sources. We may combine information we receive from these other sources with information you give to us and information we collect about you.</span></p>
		</div>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">1.2 We also collect and use aggregated data such as statistical or demographic data. Aggregated data may be derived from your personal data, but it is not considered personal data under the Data Protection Legislation as it does not directly or indirectly identify you. If at any time we do combine any aggregated data with your personal data so that it can identify you, we treat the combined data as personal data, which we will use and process in accordance with this Privacy and Cookies Policy.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">2. Cookies</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">2.1 We and our service providers collect information about your use of our website from cookies. A cookie is a small file which asks permission to be placed on your computer&apos;s hard drive. Once it is, it helps analyze website traffic or lets us know when you visit a particular site. Cookies allow website applications to respond to you as an individual.&nbsp;</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">2.2 We use traffic log cookies to identify which of our website pages are being used. This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from our system.</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">2.3 Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">2.4 You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer.&nbsp;</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">3. Purposes and legal basis for using your personal data</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1 We also use the personal data we hold about you to pursue our legitimate interests in improving our website, services, and interactions with you and other users of our products and services in the following ways:</span></p>
		<div className="priv_pol_div">
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.1 administer your account and relationship with us and communicate with you through email, instant messaging, or other electronic means;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.2 provide you with information about the products and services that you request from us;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.3 notify you about changes to our services;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.4 ensure that content from our website is presented in the most effective manner for you and your device;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.5 to administer our website and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.6 to improve the service we offer you and to try and ensure that you get the best from our website;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.7 to allow you to participate in interactive features of our website and services;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.8 as part of our efforts to keep our website safe and secure;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.9 for training and quality purposes;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.1.10 to check any instructions you give to us and for the purposes of investigating any complaint you may make, or as evidence in any dispute or anticipated disputes between you and us.</span></p>
		</div>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">3.2 In some cases we may also use the personal data we hold about you to comply with our legal obligations, or enter into or perform a contract with you.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">4. Who your data can be disclosed to</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.1 We may need to disclose your data to others to ensure the effective provision to you of the products, services, and information you request. Your data may also be disclosed to the other entities as described below.</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.2 We may share your personal information with any member of our group.&nbsp;</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.3 We may share your information with selected third parties including:</span></p>
		<div className="priv_pol_div">
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.3.1 Business partners and sub-contractors;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.3.2 Fraud prevention agencies, to prevent crime and trace those responsible;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.3.3 Analytics and search engine providers that assist us in the improvement and optimization of our websites, managing interactive aspects of our websites and the collection of information through our websites;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.3.4 IT and software providers who supply us with our IT infrastructure for the provision of our services and administering our business (including our internal and external communications) and who also help us manage our customer and contact databases, customer relationships, and marketing.</span></p>
		</div>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.4 We may disclose your personal information to third parties if:</span></p>
		<div className="priv_pol_div">
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.4.1 we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.4.2 Bitformance.com or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets; and</span></p>
		    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.4.3 We are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply any contract with you and other agreements; or to protect the rights, property, or safety of our business, our customers, or others.</span></p>
		</div>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">4.5 We review all our relationships with third parties carefully so that we can be sure that their practices match our own commitments to you relating to privacy and security. We also comply with the Data Protection Legislation in our dealings with these third parties to ensure that your information is appropriately protected.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">5. Where we store and transfer your data</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">5.1 Where we store your information ourselves it is stored on our secure servers in the US. However, where we share your information with third parties this may involve transferring it to a country outside the US. This may include countries that do not have data protection laws that are as strong as those in the US. Where we do this we will take the steps required under the Data Protection Legislation to ensure that your information is appropriately protected. If you would like any further information about this then please contact us using the details in the Contact section below.</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">5.2 Unfortunately, the transmission of information via the Internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our website; any transmission is at your own risk. Once we have received your information, we will use appropriate procedures and security features to try to prevent unauthorized access, loss, or damage.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">6. How long we keep your information for</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">6.1 How long we keep your information will depend on the purpose for which we use it. We will only retain your information for as long as is necessary for the purposes set out in this Privacy and Cookies Policy and as is necessary to comply with our legal obligations. We do not keep more information than we need for a particular purpose.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">7. Third party websites</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">7.1 Our website and other information we send to you (including any emails) may contain links to other websites. If you follow a link to any of these websites these websites may collect information from you. Please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these websites or their policies. Please check these policies before you submit any personal data to these websites.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">8. Your rights</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">8.1 Under the Data Protection Legislation, you have certain rights in respect of the personal data we hold about you. These may include rights to: request a copy of the personal data that we hold, request that we correct personal data if it is inaccurate, request that we erase or block your personal data, and to object to our processing of your personal data. These rights are limited in some situations. For example, if we have a legal requirement or a compelling legitimate ground we may continue to process your data even where you request its deletion.</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">8.2 If you would like to exercise any of these rights, please contact us via email or our website.</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">8.3 You also have the right to make a complaint if you feel your personal data has been mishandled.&nbsp;</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">9. Changes to our privacy and cookie policy</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">9.1 Any changes we may make to our Privacy and Cookie Policy in the future will be posted on this page and, where we consider it appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy and Cookie Policy.</span></p>
	    <h2 className="priv_pol_div_h2"><strong><span className="priv_pol_div_h2_span">10. Contact</span></strong></h2>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">10.1 Questions, comments, and requests regarding this Privacy and Cookie Policy should be addressed to contact@Bitformance.com</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">&nbsp;</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">&nbsp;</span></p>
	    <p className="priv_pol_div_p"><span className="priv_pol_p_span2">&nbsp;</span></p>

		<p className="priv_pol_p2"><span className="priv_pol_p_span2">&nbsp;</span></p>
		<p className="priv_pol_p2"><span className="priv_pol_p_span2">&nbsp;</span></p>
		<p className="priv_pol_p2"><span className="priv_pol_p_span2">&nbsp;</span></p>
		</div>
	)
}
