import React, { useState } from 'react';

const SubscriptionPlans = ({ currentSubscription, setShowModalType }) => {

  const isDisabled = (required_level) => {
	const tier_levels = {
		'free':1,
		'premium':2,
		'professional':3,
		'enterprise':4
	}

	const level = tier_levels[currentSubscription]
	// console.log(level,required_level)
	if (level >= required_level) {
		return true;
	} else {
		return false
	}
    // if (currentSubscription === 'enterprise') return true;
    // if (currentSubscription === 'professional' && tier === 'professional' && tier !== 'enterprise') return true;
    // if (currentSubscription === 'premium' &&  tier === 'premium' && tier !== 'professional' && tier !== 'enterprise') return true;
    // if (currentSubscription === 'free' && tier === 'free') return true;
    // return false;
  };

  return (
    <div className="min-h-screen">
	  <div  className="flex flex-col items-center mt-5 pt-5 bg-gray-100">
		  <div className="flex justify-center mb-5">
			<button
			  className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600`}
			  onClick={() => setShowModalType('BETA_ACCESS')}
			>
			  Apply for BETA Access
			</button>
		  </div>
	  </div>
    </div>
  );
};

export default SubscriptionPlans;
