import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { useSectorIndexes } from '../endpoints/index'; // Adjust the import path as needed

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PieController,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PieController,
  ArcElement,
  Tooltip,
  Legend
);

const generateColor = (index) => {
  const colors = [
    'rgba(54, 162, 235, 0.5)', // Blue
    'rgba(255, 99, 132, 0.5)', // Red
    'rgba(255, 206, 86, 0.5)', // Yellow
    'rgba(75, 192, 192, 0.5)', // Green
    'rgba(153, 102, 255, 0.5)', // Purple
    'rgba(255, 159, 64, 0.5)', // Orange
    'rgba(199, 199, 199, 0.5)', // Grey
    'rgba(83, 102, 255, 0.5)', // Violet
    'rgba(201, 203, 207, 0.5)', // Light Grey
  ];
  return colors[index % colors.length];
};

const generateBorderColor = (index) => {
  const borderColors = [
    'rgba(54, 162, 235, 1)', // Blue
    'rgba(255, 99, 132, 1)', // Red
    'rgba(255, 206, 86, 1)', // Yellow
    'rgba(75, 192, 192, 1)', // Green
    'rgba(153, 102, 255, 1)', // Purple
    'rgba(255, 159, 64, 1)', // Orange
    'rgba(199, 199, 199, 1)', // Grey
    'rgba(83, 102, 255, 1)', // Violet
    'rgba(201, 203, 207, 1)', // Light Grey
  ];
  return borderColors[index % borderColors.length];
};

const SectorPieChart = () => {
  const { data, isLoading } = useSectorIndexes('market_cap', 'desc');
  const [chartData, setChartData] = useState(null);
  const [isSubsectorView, setIsSubsectorView] = useState(false);

  useEffect(() => {
    if (data) {
      updateChartData();
    }
  }, [data]);

  const updateChartData = () => {
    const filteredData = data.filter((index) => !index.name.includes('EQW'));

    const totalMarketCap = filteredData.reduce((sum, index) => sum + index.marketcap, 0);
    const labels = filteredData.map((index) => index.name);
    const values = filteredData.map((index) => index.marketcap);
    const percentages = values.map((value) => (value / totalMarketCap) * 100);

    const backgroundColors = values.map((_, idx) => generateColor(idx));
    const borderColors = values.map((_, idx) => generateBorderColor(idx));

    setChartData({
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: backgroundColors,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
      percentages,
    });
    setIsSubsectorView(false);
  };

  const handleSliceClick = (elements) => {
    if (elements.length === 0) return;

    const index = elements[0].index;
    const sectorName = chartData.labels[index];
    const selectedSector = data.find((index) => index.name === sectorName);

    if (selectedSector && selectedSector.subsectors) {
      const subsectorLabels = selectedSector.subsectors.map((sub) => sub.index.name);
      const subsectorValues = selectedSector.subsectors.map((sub) => sub.index.marketcap);
      const totalMarketCap = subsectorValues.reduce((sum, value) => sum + value, 0);
      const percentages = subsectorValues.map((value) => (value / totalMarketCap) * 100);

      const backgroundColors = subsectorValues.map((_, idx) => generateColor(idx));
      const borderColors = subsectorValues.map((_, idx) => generateBorderColor(idx));

      setChartData({
        labels: subsectorLabels,
        datasets: [
          {
            data: subsectorValues,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1,
          },
        ],
        percentages,
      });
      setIsSubsectorView(true);
    }
  };

  const handleBackClick = () => {
    updateChartData();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-main-white border-[1px] border-main-lightGrayBorder sm:rounded-2xl p-4 mb-4">
      <h2 className="text-lg font-semibold mb-4">Sector Weighting</h2>
      <div className="mb-4">
        {isSubsectorView && (
          <button
            className="px-2 py-1 bg-gray-200 text-sm"
            onClick={handleBackClick}
          >
            &lt; Back
          </button>
        )}
      </div>
      {chartData ? (
        <>
          <div style={{ height: '400px' }}>
            <Pie
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const value = context.raw.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
                        const percentage = chartData.percentages[context.dataIndex].toFixed(2);
                        const label = context.label;
                        return `${label}: ${value} (${percentage}%)`;
                      },
                    },
                  },
                  legend: {
                    display: true,
                    position: 'top',
                  },
                  title: {
                    display: true,
                    text: 'Total Market Cap',
                  },
                },
                onClick: (event, elements) => handleSliceClick(elements),
              }}
            />
          </div>
          <div className="overflow-x-auto mt-4">
            <table className="w-full text-left text-sm">
              <thead>
                <tr>
                  <th className="py-2 px-4">Name</th>
                  <th className="py-2 px-4">Market Cap</th>
                  <th className="py-2 px-4">Percentage</th>
                </tr>
              </thead>
              <tbody>
                {chartData.labels.map((label, idx) => (
                  <tr key={label}>
                    <td className="py-2 px-4">{label}</td>
                    <td className="py-2 px-4">{chartData.datasets[0].data[idx].toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 })}</td>
                    <td className="py-2 px-4">{chartData.percentages[idx].toFixed(2)}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div>No data available</div>
      )}
    </div>
  );
};

export default SectorPieChart;
