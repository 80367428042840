import BFIcon from '../BFIcon'
import ReCAPTCHA from "react-google-recaptcha"
import CloseModal from '../small/CloseModal'
import { contactFormBetaAccess } from '../../endpoints/index'
import { useState } from 'react'
import { validateEmail } from '../small/validation'

export default function BetaAccessModal({ setShowModalType }) {

	const [formSendSuccess, setFormSendSuccess] = useState(false)
	const [completedRecaptcha, setCompletedRecaptcha] = useState(true)
	const [errorMessage, setErrorMessage] = useState('')

	const sendContactForm = async (e) => {
		e.preventDefault()
		setFormSendSuccess(false)
		setErrorMessage('')
		const formData = new FormData(e.target);
		const keys = [
			'username',
			'email',
			'telegram',
			'twitter',
			'tier',
			'message'
		]

		let data = {}
		keys.forEach(key => data[key] = formData.get(key))

		if (!completedRecaptcha) {
			setErrorMessage("Recaptcha Error!")
			// setIsLoading(false)
			return
		}

		//error check
		const emailError = validateEmail(data.email)
		if (emailError) {
			setErrorMessage(emailError)
			// setIsLoading(false)
			setFormSendSuccess(false)
			return
		}

		const result = await contactFormBetaAccess({ ...data })
		if (result.result) {
			setFormSendSuccess(true)
			return
		} else {
			setErrorMessage("Error sending email!")
			setFormSendSuccess(false)
			return
		}

		//TODO: error message
		setShowModalType('')
	}

	const onRecaptchaChange = (value) => {
		setCompletedRecaptcha(true)
	}



	return(
		<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
		  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
		    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 bg-main-lightGray">
		    	<CloseModal setShowModalType={setShowModalType} />
		      <div className="sm:flex items-center">
		        <div className="mx-auto flex h-12 w-12 text-[50px] flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
		          <BFIcon color="#40C8B8" iconName="contact-us" />
		        </div>
		        <div className="text-center sm:mt-0 sm:ml-6 sm:text-left">
		          <h3 className="text-[22px] font-DM_Sans font-medium leading-normal tracking-normal text-main-black" id="modal-title">Apply for BETA Access</h3>
		        </div>
		      </div>
		    </div>

		    {formSendSuccess ?
		    	<div className="mx-auto text-[14px] p-8 space-y-4 text-center font-DM_Sans">
		    		<p>Thanks for reaching out! We'll get back to you as soon as possible.</p>
					<button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={() => setShowModalType('')}>
			          Close
			        </button>
		    	</div>
		    :
			    <form onSubmit={(e) => sendContactForm(e)}>
				    <div className="p-4 space-y-4">
				    	<div className='text-[16px] font-DM_Sans leading-normal tracking-normal'>
				    		<label className="text-main-black font-medium">Username</label>
				    		<input name="username" className="w-full px-2 py-2 mt-2 text-sm border rounded-md bg-main-inputBackground text-main-black font-normal" type="text" placeholder="e. g. johndoe" required/>
				    	</div>
				    	<div className='text-[16px] font-DM_Sans leading-normal tracking-normal'>
				    		<label className="text-main-black font-medium">Email Address</label>
				    		<input name="email" className="w-full px-2 py-2 mt-2 text-sm border rounded-md bg-main-inputBackground text-main-black font-normal" type="text" placeholder="e. g. yourname@gmail.com" required/>
				    	</div>
				    	<div className='text-[16px] font-DM_Sans leading-normal tracking-normal'>
				    		<label className="text-main-black font-medium">Telegram (optional)</label>
				    		<input name="telegram" className="w-full px-2 py-2 mt-2 text-sm border rounded-md bg-main-inputBackground text-main-black font-normal" type="text" placeholder="@your-telegram-username" />
				    	</div>
				    	<div className='text-[16px] font-DM_Sans leading-normal tracking-normal'>
				    		<label className="text-main-black font-medium">Twitter (optional)</label>
				    		<input name="twitter" className="w-full px-2 py-2 mt-2 text-sm border rounded-md bg-main-inputBackground text-main-black font-normal" type="text" placeholder="@your-twitter-handle" />
				    	</div>
						{errorMessage &&
						<div className="flex flex-row gap-2 items-center text-[12px]">
				    		<span className='pb-5'><BFIcon iconName="info" color="rgb(239 68 68)" /></span>
				    		<p className="text-[16px] text-red-500 font-DM_Sans leading-5 tracking-normal font-normal">{errorMessage}</p>
				    	</div>}
						<div className='text-[16px] font-DM_Sans leading-normal tracking-normal'>
						    <label className="text-main-black font-medium">Tier Interested</label>
						    <select
						        name="tier"
						        className="w-full px-2 py-2 mt-2 text-sm border rounded-md bg-main-inputBackground text-main-black font-normal"
								required
						    >
						        <option value="">Select a Tier</option>
						        <option value="Premium">Premium</option>
						        <option value="Professional">Professional</option>
						        <option value="Enterprise">Enterprise</option>
						    </select>
						</div>
				    	<div className='text-[16px] font-DM_Sans leading-normal tracking-normal '>
				    		<label className="text-main-black font-medium">Where did you hear about us?</label>
				    		<br />
				    		<textarea name="message" className='w-full px-2 py-2 mt-2 text-sm border rounded-md h-32 text-main-placeholder font-normal' required></textarea>
				    	</div>

				    	<ReCAPTCHA
				    	  sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
				    	  onChange={onRecaptchaChange}
				    	/>

				    	<hr />
				    	<div className="mt-auto flex flex-row gap-6">
				    		<button className="w-full rounded-lg bg-main-gColor bg-opacity-10 text-[15px] font-DM_Sans font-bold leading-normal tracking-normal text-main-gColor py-2" onClick={() => setShowModalType('')}>Cancel</button>
				    		<button type="submit" className="w-full rounded-lg bg-main-gColor text-[15px] font-DM_Sans font-bold leading-normal tracking-normal shadow-sm shadow-main-shadowBlue text-white py-2">Submit</button>
				    	</div>
			    	</div>
			    </form>
		    }

		  </div>
		</div>
	)
}
