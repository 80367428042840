import { calculateDrawdown, calculateReturn } from "../../helpers"
import BFIcon from "../BFIcon"

export default function BFPerformance({
	data,
	fixedReturn,
	totalReturn,
	fixedDrawdown,
	drawdown
}) {

	if (totalReturn == 0) {
		totalReturn = fixedReturn
	}
	if (drawdown == 0) {
		drawdown = fixedDrawdown
	}

	const formatter = new Intl.NumberFormat('en-US');

	const UpTag = ({ value }) => (
		<span className="text-[30px] font-DM_Sans font-medium leading-normal tracking-normal text-main-green metric"><BFIcon iconName="up-right-arrow" /> {formatter.format(Math.abs(value).toFixed(2))}%</span>
	)

	const DownTag = ({ value }) => (
		<span className="text-[30px] font-DM_Sans font-medium leading-normal tracking-normal text-main-deepOrange metric"><BFIcon iconName="down-left-arrow" /> {formatter.format(Math.abs(value).toFixed(2))}%</span>
	)
	return (<>

			<div className={`hidden xl:block bg-white absolute bottom-0`}>
				<h2 className="text-[22px] font-DM_Sans font-medium leading-normal tracking-normal text-main-black">Performance Metrics</h2>
				<div className="pt-4 flex flex-col justify-between items-start">
					<div className="w-full flex flex-col">
						<p className="flex flex-col items-start drawdown">
							{drawdown > 0 ? <UpTag value={drawdown || fixedDrawdown} /> : <DownTag value={drawdown || fixedDrawdown} /> }
							<span className="ml-2 mt-0.5 text-[16px] font-DM_Sans font-normal leading-normal tracking-normal text-main-black" id="drawdown">Maximum Drawdown</span>
						</p>

						<div className='hidden md:block border-r-[1px] my-2 border-main-lightGrayBorder'/>

						<div className='border-t-[1px] my-2 border-main-lightGrayBorder'/>

						<p className="flex flex-col items-start totalReturn">
							{totalReturn > 0 ? <UpTag value={totalReturn || fixedReturn} /> : <DownTag value={totalReturn || fixedReturn} /> }
							<span className="ml-2 mt-0.5 text-[16px] font-DM_Sans font-normal leading-normal tracking-normal text-main-black" id="total_return">Total Return</span>
						</p>
					</div>
				</div>
			</div>
	</>)
}
