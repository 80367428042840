import ContactUs from './modals/ContactUsModal'
import SignIn from './modals/SignInModal'
import SignUp from './modals/SignUpModal'
import EmailConfirmation from './modals/EmailConfirmationModal'
import CheckEmail from './modals/CheckEmailModal'
import ConfirmPassword from './modals/ConfirmPasswordModal'
import CheckEmailApi from './modals/CheckEmailApiModal'
import BetaAccess from './modals/BetaAccessModal'

export default function BFModal({ showModalType, setShowModalType }) {


	const modals = [
		{ type: 'CONTACT_US' , component: <ContactUs setShowModalType={setShowModalType} /> },
		{ type: 'SIGN_UP', component: <SignUp setShowModalType={setShowModalType} /> },
		{ type: 'SIGN_IN', component: <SignIn setShowModalType={setShowModalType} /> },
		{ type: 'CONFIRM_EMAIL', component: <EmailConfirmation setShowModalType={setShowModalType} /> },
		{ type: 'CHECK_EMAIL', component: <CheckEmail setShowModalType={setShowModalType} /> },
		{ type: 'CONFIRM_PASSWORD', component: <ConfirmPassword setShowModalType={setShowModalType} />},
		{ type: 'CHECK_EMAIL_API', component: <CheckEmailApi setShowModalType={setShowModalType} />},
		{ type: 'BETA_ACCESS', component: <BetaAccess setShowModalType={setShowModalType} />}
	]

	const modal = modals.find(obj => obj.type === showModalType)

	return(
		<div className="relative z-[60]" aria-labelledby="modal-title" role="dialog" aria-modal="true">
		  <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
		  <div className="fixed inset-0 z-[70] overflow-y-auto">
		    {modal.component}
		  </div>
		</div>

	)
}
