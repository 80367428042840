export default function TermsPage() {
	return (
		<div className="m-4 pt-3">
		<h1 className="tos_h1"><strong><span className="tos_h1_span">Website Terms of Use</span></strong></h1>
		<p className="tos_p"><strong><span className="tos_p_span">Please read these Terms of Use carefully before using this Site.</span></strong></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">1. About our Terms of Use (the &ldquo;Terms&rdquo;)</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">1.1 Reference in these Terms to the Site is intended for our website, www.bitformance.com, and all associated web pages (together, the &ldquo;<strong>Site</strong>&rdquo;).</span></p>
		<p className="tos_p"><span className="tos_p_span">1.2 These Terms explain how you may use our website. You should read these Terms carefully before using the Site.</span></p>
		<p className="tos_p"><span className="tos_p_span">1.3 By accessing or using the Site or otherwise indicating your consent, you agree to be bound by these Terms and the documents referred to in them and you agree to comply with them. If you do not agree with or accept any of these Terms, you should stop using the Site</span></p>
		<p className="tos_p"><span className="tos_p_span">1.4 We recommend that you print a copy of these Terms for future reference.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">2. Who we are and how to contact us</span></strong></h2>
		<p className="tos_p2">2.1 Bitformance.com, (<span className="tos_p_span">&quot;<strong>We</strong>&quot;, &quot;<strong>Us</strong>&quot;<strong>&nbsp;</strong>or &quot;<strong>Our</strong>&quot;</span>)</p>
		<p className="tos_p"><span className="tos_p_span">2.2 To contact us, please email contact@bitformance.com</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">3. Definitions</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">&quot;<strong>Content</strong>&quot;<strong>&nbsp;</strong>means any data information, software, or other material on or submitted to the Site or included in any communication that we send to You, in each case whether that is text, images, video, audio, or other multimedia content or otherwise;<br /> &quot;<strong>Privacy and Cookie Policy</strong>&quot; means the policy that governs how We process any personal data we collect and process about you;<br /> &quot;<strong>Site</strong>&quot; has the meaning given to it in clause 1.2;<br /> &quot;<strong>Terms</strong>&quot; means these terms and conditions of use as updated from time to time under clause 13;<br /> &quot;<strong>User Content</strong>&quot; has the meaning given to it in clause 8.1;<br /> &quot;<strong>We</strong>&quot;, &quot;<strong>Us</strong>&quot;<strong>&nbsp;</strong>or &quot;<strong>Our</strong>&quot; has the meaning given in clause 2.1; and<br /> &quot;<strong>You</strong>&quot; or &quot;<strong>Your</strong>&quot; means the person accessing or using the Site and the Content.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">4. Other Terms</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">Our Privacy Policy also applies to your use of this Site.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">5. Important information about Our Site and the Content and Your use of it</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">5.1 The Site and the Content is for Your personal and non-commercial use only.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.2 The Site and the Content provided by Us and Our third party providers are for Your general information only and is not intended for trading or investment purposes or to address Your particular financial or other requirements. In particular, the Content:</span></p>
		<div className="tos_div">
		    <p className="tos_div_span"><span className="tos_p_span">5.2.1 does not constitute any form of advice (financial, investment, tax, legal, or otherwise) and should not be relied on for any purposes;</span></p>
		    <p className="tos_div_span"><span className="tos_p_span">5.2.2 does not constitute any inducement, invitation, or recommendation relating to any of the products or services listed or referred to; and</span></p>
		    <p className="tos_div_span"><span className="tos_p_span">5.2.3 is not intended to be relied upon by You in making (or deciding not to make) any specific investment or other decisions.</span></p>
		    <p className="tos_div_span"><span className="tos_p_span">YOU SHOULD OBTAIN APPROPRIATE EXPERT INDEPENDENT FINANCIAL ADVICE BEFORE MAKING ANY INVESTMENT OR OTHER DECISIONS.</span></p>
		</div>
		<p className="tos_p"><span className="tos_p_span">5.3 The Site and the Content may include information and materials uploaded by other users of the Site. The views expressed by other users on Our Site do not represent Our views or values.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.4 Any use You make of this Site and any Content must at all times comply with all applicable laws and regulations and You must not use the Site or any Content in connection with a criminal offense under any applicable laws or regulations or for any unlawful purpose whatsoever or which promotes any unlawful act.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.5 You also must not use this Site or any Content in any way that is abusive, harmful, threatening, or defamatory or any other way that may cause offense or in any way which causes or is intended to cause annoyance or inconvenience, or which may otherwise damage Our reputation.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.6 You agree that You are solely responsible for all costs and expenses You may incur in relation to Your use of the Site and the Content.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.7 The Site and the Content are intended for use only by those who can access it from within the US. If You choose to access the Site from locations outside the US, You are responsible for compliance with local laws where they are applicable.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.8 We seek to make the Site as accessible as possible. If You have any difficulties using the Site, please contact Us at the contact details provided in clause 2.2.</span></p>
		<p className="tos_p"><span className="tos_p_span">5.9 We may prevent or suspend Your access to the Site if You do not comply with any part of these Terms, any terms or policies to which they refer, or any applicable law.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">6. Registered users</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">6.1 You are solely responsible for keeping any password, user identification code, or other login details confidential.</span></p>
		<p className="tos_p"><span className="tos_p_span">6.2 You will also be responsible for any use made of this Site or the Content by anyone using your password, user identification code, or other login details.</span></p>
		<p className="tos_p"><span className="tos_p_span">6.3 If You know or suspect that anyone other than You knows Your password, user identification code, or other login details, You must promptly notify Us.</span></p>
		<p className="tos_p"><span className="tos_p_span">6.4 You are not permitted to impersonate any other person and We reserve the right in Our absolute discretion to restrict the use of any particular password, user identification code, or other login details.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">7. Ownership, use, and intellectual property rights</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">7.1 This Site, the Content (other than Your User Content), and all intellectual property rights in it are owned by Us or Our licensors. Intellectual property rights mean rights such as copyright, trademarks, domain names, design rights, database rights, patents, and all other intellectual property rights of any kind whether or not they are registered or unregistered (anywhere in the world). We reserve all of Our rights in any intellectual property in connection with these Terms. This means, for example, that We remain owners of them and are free to use them as We see fit.</span></p>
		<p className="tos_p"><span className="tos_p_span">7.2 Nothing in these Terms grants You any legal rights in the Site and the Content other than as necessary to enable You to access the Site and the Content. You agree not to adjust, to try to circumvent, or delete any notices contained on the Site or on any emails We send to You (including any intellectual property notices) and in particular in any digital rights or other security technology embedded or contained within the Site.</span></p>
		<p className="tos_p"><span className="tos_p_span">7.3 You may only make a copy of any part of Our Site or the Content as is reasonably necessary for Your own personal use or as You otherwise agree with Us in writing. You must not modify any Content You copy from Our Site or any email we send You in any way. Our status (and that of any identified contributors) as the authors of Content must always be acknowledged. If You print off, copy, or download any part of Our Site or the Content, in breach of these Terms, Your right to use Our Site and the Content will cease immediately and You must return or destroy any copies of the materials You have made.</span></p>
		<p className="tos_p"><span className="tos_p_span">7.4 Where you submit any User Content to the Site you grant Us a perpetual, royalty-free, worldwide license to use, display, copy, amend, and create derivate works from that User Content for any purpose, including commercial purposes.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">8. Submitting information to the Site</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">8.1 We may from time to time enable features, such as user forums, reviews, bulletin boards, and chat rooms, that allow you to upload comments, messages, photos, videos, or other content (the &quot;<strong>User Content</strong>&quot;). If you use any such feature you must comply with the provisions of this clause 8.</span></p>
		<p className="tos_p"><span className="tos_p_span">8.2 By uploading any User Content you acknowledge and agree that you are the author and owner of that User Content and all rights in it and that it complies with all applicable laws, does not infringe any rights of any third party, and is not obscene, defamatory or likely to be offensive to any other person and will not cause any damage to Our reputation. You also must not upload any User Content that could be deemed to be junk or spam or, unless we agree in writing otherwise, to advertise any goods or services.</span></p>
		<p className="tos_p"><span className="tos_p_span">8.3 You also agree that we have the right to delete any of the User Content that you may upload from time to time and to edit it and use it as we see fit on our Site and in connection with any of our products or services as may exist from time to time, and in connection with any promotion, publicity or marketing of our Site, products or services as may exist from time to time.</span></p>
		<p className="tos_p"><span className="tos_p_span">8.4 We have the right to disclose Your identity to any third party who is claiming that any User Content posted or uploaded by You to Our Site constitutes a violation of their intellectual property rights or their right to privacy. We have the right to remove any User Content You post on Our Site.</span></p>
		<p className="tos_p"><span className="tos_p_span">8.5 You must not misuse Our Site by knowingly introducing viruses, trojans, worms, logic bombs, or other material that is malicious or technologically harmful. You must not attempt to gain unauthorized access to Our Site, the server on which Our Site is stored or any server, computer, or database connected to Our Site. You must not attack Our Site via a denial-of-service attack or a distributed denial-of-service attack. By breaching this provision, You would commit a criminal offense under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and We will co-operate with those authorities by disclosing Your identity to them. In the event of such a breach, Your right to use Our Site will cease immediately.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">9. Accuracy and availability of the Site</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">9.1 While We try to make sure that the Site and the Content is accurate, up-to-date, and free from bugs and viruses, neither We nor any of the third-party providers of data or information on our Site make any representations, warranties, or guarantees, whether express or implied, that the Content on Our Site and that we sent to You is accurate, complete or up to date. You should use Your own virus protection software. Furthermore, We cannot promise that the Site or any of the Content will be fit or suitable for any purpose.</span></p>
		<p className="tos_p"><span className="tos_p_span">9.2 While We try to make sure that the Site is available for Your use, We do not promise that the Site is available at all times nor do We promise the uninterrupted use by You of the Site or that any Content is up-to-date or not delayed. We may suspend or terminate operation of the Site at any time as We see fit.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">10. Links and third-party sites</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">10.1 The Site and the Content (including information we send to You) may contain links or references to third-party websites. Any such links or references are provided for Your convenience only. We have no control over third-party websites and accept no legal responsibility for any content, material, or information contained in them. The display of any hyperlink and reference to any third-party website does not mean that We endorse that third party&apos;s website, products, or services. Your use of a third-party website may be governed by the terms and conditions of that third-party website.</span></p>
		<p className="tos_p"><span className="tos_p_span">10.2 You may link to Our home page, provided You do so in a way that is fair and legal and does not damage Our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval, or endorsement on Our part where none exists. You must not establish a link to Our Site on any website that is not owned by You. We reserve the right to withdraw linking permission without notice. If You wish to link to or make any use of Content on Our Site other than that set out above, please contact Us.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">11. Our responsibility for loss or damage suffered by You</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">11.1 We do not exclude or limit in any way Our liability to You where it would be unlawful to do so. This includes liability for death or personal injury caused by Our negligence or the negligence of Our employees, agents, or subcontractors and for fraud or fraudulent misrepresentation.</span></p>
		<p className="tos_p"><span className="tos_p_span">11.2 To the maximum extent permitted by law We exclude all implied conditions, warranties, representations, or other terms that may apply to Our Site or any Content on it or that we send to You.</span></p>
		<p className="tos_p"><span className="tos_p_span">11.3 We will not be liable to You for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with the use of, or inability to use, Our Site; or use of or reliance on any Content displayed on Our Site or that we send to You.</span></p>
		<p className="tos_p"><span className="tos_p_span">11.4 In particular, We will not be liable for:</span></p>
		<p className="tos_p"><span className="tos_p_span">11.4.1 loss of profits, sales, business, revenue, anticipated savings, business opportunity, goodwill or reputation, or any business interruption; or</span></p>
		<p className="tos_p"><span className="tos_p_span">11.4.2 any indirect or consequential loss or damage.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">12. Rights of third parties</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">No one other than a party to these Terms has any right to enforce any of these Terms.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">13. Variation</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">No changes to these Terms are valid or have any effect unless agreed by Us in writing. We reserve the right to vary these Terms from time to time. Our updated terms will be displayed on the Site and by continuing to use and access the Site following such changes, You agree to be bound by any variation made by Us. It is Your responsibility to check these Terms from time to time to verify such variations.</span></p>
		<h2 className="tos_h2"><strong><span className="tos_h2_strong_span">14. Disputes</span></strong></h2>
		<p className="tos_p"><span className="tos_p_span">These Terms, their subject matter, and their formation (and any non-contractual disputes or claims) are governed by the laws of California, USA. Parties to these Terms agree to the exclusive jurisdiction of the courts of California, USA.</span></p>
		<p className="tos_p"><span className="tos_p_span">&nbsp;</span></p>
		<p className="tos_p2">&nbsp;</p>
		</div>
	)
}
