import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { useSectorIndexes } from '../endpoints/index'; // Adjust the import path as needed

const SectorHeatmap = () => {
  const [timeframe, setTimeframe] = useState('changepct_24hour');
  const [toggleIndexType, setToggleIndexType] = useState('market_cap');
  const [isSubsectorView, setIsSubsectorView] = useState(false);
  const [subsectorData, setSubsectorData] = useState(null);
  const { data, isLoading } = useSectorIndexes('market_cap', 'desc');
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (data) {
      createTreemap(isSubsectorView ? subsectorData : data);
    }
  }, [data, timeframe, toggleIndexType, isSubsectorView]);

  const createTreemap = (data) => {
    const width = 550;  // Adjusted width for better fit
    const height = 500; // Adjusted height for better fit

    const svg = d3.select(chartRef.current)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .style('overflow', 'hidden');

    svg.selectAll('*').remove(); // Clear any existing content

    const filteredData = data.filter(index => toggleIndexType === 'market_cap' ? !index.name.includes('EQW') : index.name.includes('EQW'));
    const normalizedData = normalizeMarketCap(filteredData);

    const root = d3.hierarchy({ children: normalizedData })
      .sum(d => d.marketcap);

    d3.treemap()
      .size([width, height])
      .padding(1)
      (root);

    svg.selectAll('rect')
      .data(root.leaves())
      .enter()
      .append('rect')
      .attr('x', d => d.x0)
      .attr('y', d => d.y0)
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .attr('fill', d => getColor(d.data[timeframe]))
      .attr('stroke', '#fff')
      .on('mouseover', (event, d) => {
        const [x, y] = d3.pointer(event);
        d3.select(tooltipRef.current)
          .style('left', `${x}px`)
          .style('top', `${y}px`)
          .style('opacity', 1)
          .html(`<strong>${d.data.name}</strong><br>Market Cap: ${formatMoney(d.data.marketcap)}<br>Change: ${d.data[timeframe].toFixed(2)}%`);
      })
      .on('mousemove', (event) => {
        const [x, y] = d3.pointer(event);
        d3.select(tooltipRef.current)
          .style('left', `${x}px`)
          .style('top', `${y}px`);
      })
      .on('mouseout', () => {
        d3.select(tooltipRef.current)
          .style('opacity', 0);
      })
      .on('click', (event, d) => {
        if (d.data.subsectors) {
          setSubsectorData(normalizeMarketCap(d.data.subsectors.map(sub => ({
            ...sub.index,
            name: sub.index.name,
            marketcap: sub.index.marketcap,
            [timeframe]: sub.index[timeframe]
          })), true));
          setIsSubsectorView(true);
        }
      });

    svg.selectAll('text')
      .data(root.leaves())
      .enter()
      .append('text')
      .attr('x', d => (d.x0 + d.x1) / 2) // Center horizontally
      .attr('y', d => (d.y0 + d.y1) / 2) // Center vertically
      .attr('dy', '.35em') // Align text vertically in the center
      .attr('text-anchor', 'middle') // Center the text
      .text(d => d.data.name)
      .attr('font-size', d => Math.min(18, (d.x1 - d.x0) / d.data.name.length * 1.5)) // Dynamic font size based on block size
      .attr('font-weight', 'bold') // Made font bold
      .attr('fill', 'white'); // Changed font color to white
  };

  const normalizeMarketCap = (data, isSubsector = false) => {
	console.log(data)
    const maxMarketCap = Math.max(...data.map(d => d.marketcap));
    const capValue = isSubsector ? maxMarketCap * 0.05 : maxMarketCap * 0.1; // Cap the size differently for subsectors
    return data.map(d => ({
      ...d,
      marketcap: Math.min(d.marketcap, capValue),
    }));
  };

  const getColor = (value) => {
    if (value > 0.2) {
      return `rgba(0, 128, 0, ${0.5 + value / 20})`; // Green shades
    } else if (value < -0.2) {
      const alpha = Math.min(0.7, 0.4 + Math.abs(value) / 20); // Cap the alpha at 0.7 for the brightest red and make it slightly darker
      return `rgba(204, 0, 0, ${alpha})`; // Darker red shades
    } else {
      return 'rgba(128, 128, 128, 0.5)'; // Gray color for neutral values
    }
  };

  const formatMoney = (value) => {
    return value != null && !isNaN(value) ? `$${(value / 1e9).toFixed(2)}B` : 'N/A';
  };

  const handleBackClick = () => {
    setIsSubsectorView(false);
    setSubsectorData(null);
  };

  const handleIndexTypeChange = (type) => {
    setToggleIndexType(type);
    handleBackClick(); // Reset view when changing index type
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="relative bg-main-white border-[1px] border-main-lightGrayBorder sm:rounded-2xl p-4 overflow-hidden">
      <h2 className="text-lg font-semibold mb-4">Sector Heatmap</h2>
      <div className="flex justify-between items-center mb-4">
        <div>
          <button
            className={`px-4 py-2 mr-2 ${toggleIndexType === 'market_cap' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleIndexTypeChange('market_cap')}
          >
            Market Cap
          </button>
          <button
            className={`px-4 py-2 ${toggleIndexType === 'equal_weight' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => handleIndexTypeChange('equal_weight')}
          >
            Equal Weight
          </button>
          {isSubsectorView && (
            <button
              className="px-2 py-1 ml-2 bg-gray-200 text-sm"
              onClick={handleBackClick}
            >
              &lt; Back
            </button>
          )}
        </div>
        <div>
          <button
            className={`px-2 py-1 mr-2 text-sm ${timeframe === 'changepct_24hour' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setTimeframe('changepct_24hour')}
          >
            1d
          </button>
          <button
            className={`px-2 py-1 mr-2 text-sm ${timeframe === 'changepct_7d' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setTimeframe('changepct_7d')}
          >
            7d
          </button>
          <button
            className={`px-2 py-1 mr-2 text-sm ${timeframe === 'changepct_1m' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setTimeframe('changepct_1m')}
          >
            1m
          </button>
          <button
            className={`px-2 py-1 mr-2 text-sm ${timeframe === 'changepct_6m' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setTimeframe('changepct_6m')}
          >
            6m
          </button>
          <button
            className={`px-2 py-1 text-sm ${timeframe === 'changepct_1y' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            onClick={() => setTimeframe('changepct_1y')}
          >
            1y
          </button>
        </div>
      </div>
	  <div
        ref={tooltipRef}
        style={{
		  position: 'absolute',
          background: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '5px',
          borderRadius: '3px',
          pointerEvents: 'none',
          opacity: 0,
        }}
      ></div>
      <svg ref={chartRef}></svg>
    </div>
  );
};

export default SectorHeatmap;
