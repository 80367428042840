import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import BFIcon from '../BFIcon'
import { useMyIndexes, useFavoriteIndexes } from '../../endpoints/index'

const secondaryColor = '#40C8B8'
const whiteColor = '#ffffff'

export default function SiteLinks({ setShowModalType, setShowMobileMenu = function(){} , colorStyle = '' }) {

	const { data, isLoading } = useMyIndexes()
	const { data: favorites, isLoading: favoritesLoading } = useFavoriteIndexes()

	const navigate = useNavigate()

	const [indexesExpanded, setIndexesExpanded] = useState(true)
	const [devtoolsExpanded, setDevtoolsExpanded] = useState(true)
	const [siteSelected, setSiteSelected] = useState('')
	const [pageMode,setPageMode] = useState('')
	const [isLogin, setLogin] = useState(false)
	const username = localStorage.getItem('username')
	useEffect(() => {
		if (username) {
			setLogin(true)
		}else{
			setLogin(false)
		}
	}, [username])

	return(<>
		<Link to="/" onClick={() => setShowMobileMenu(false) & setSiteSelected('home')}
			className={ siteSelected === 'home' ?
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-[10px] bg-opacity-50`
			 :
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] ml-[10px]`
			}
		>
			<BFIcon iconName="home" color={secondaryColor} />
			<span className={colorStyle}>Home</span>
		</Link>
		<div className="flex flex-row items-center justify-between gap-2 cursor-pointer h-[40px] ml-[10px]" onClick={() => setIndexesExpanded(!indexesExpanded)}>
			<div className='flex flex-row items-start'>
				<div className='pr-2'>
					<BFIcon iconName="indexes" color={secondaryColor} />
				</div>
				<span className={colorStyle}>Indexes</span>
			</div>
			<div className='text-[7px]'>
				{
					indexesExpanded == true ? <BFIcon iconName="arrowUP" color={whiteColor}/> : <BFIcon iconName="arrowDown" color={whiteColor}/>
				}
			</div>
		</div>
		{indexesExpanded && <>
			<div className="cursor-pointer h-[40px]">
				<Link onClick={() => setShowMobileMenu(false) & setSiteSelected('browse')}  to="/indexes/browse"
				className={ siteSelected === 'browse' ?
				`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-6 bg-opacity-50`
				 :
				`ml-6 flex flex-row items-center gap-2 cursor-pointer h-[40px]`
				}
				>
					<BFIcon iconName="strategies" color="white" />
					<span className={`${colorStyle}`}>Custom Strategies</span>
				</Link>
			</div>

			<div className="cursor-pointer h-[40px]">
				<Link onClick={() => setShowMobileMenu(false) & setSiteSelected('sectors')}  to="/indexes/sectors"
				className={ siteSelected === 'sectors' ?
				`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-6 bg-opacity-50`
				 :
				`ml-6 flex flex-row items-center gap-2 cursor-pointer h-[40px]`
				}
				>
					<BFIcon iconName="knowledge-base" color="white" />
					<span className={`${colorStyle}`}>Sector/Subsector</span>
				</Link>
			</div>

			{
				isLogin && !data && <>
					<div class="ml-6 flex flex-row gap-2 items-center cursor-pointer h-[40px]">
						<BFIcon iconName="my-indexes" color="white" />
						<span className={colorStyle}>Loading Indexes...</span>
					</div>
				</>
			}

			{
				isLogin && !favorites && <>
					<div class="ml-6 flex flex-row gap-2 items-center cursor-pointer h-[40px]">
						<BFIcon iconName="my-indexes" color="white" />
						<span className={colorStyle}>Loading Favorites...</span>
					</div>
				</>
			}

			{
				isLogin && data &&
					<Link to="/indexes/my-indexes" onClick={() => setShowMobileMenu(false) & setSiteSelected('my-indexes')}
					className={ siteSelected === 'my-indexes' ?
					`flex flex-row gap-2 items-center cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg pl-6 bg-opacity-50`
					:
					`ml-6 flex flex-row gap-2 items-center cursor-pointer h-[40px]`
					}
					>
						<BFIcon iconName="my-indexes" color="white" />
						<span className={colorStyle}>My Indexes</span>
						<span className="ml-auto w-[16px] h-[22px] rounded bg-main-buttonBlue text-sm flex items-center justify-center mr-2 font-DM_Sans font-medium leading-normal tracking-normal text-main-white">{data ? data.length : ''}</span>
					</Link>
			}

			{
				isLogin && favorites &&

					<Link to="/indexes/my-favorites" onClick={() => setShowMobileMenu(false) & setSiteSelected('my-favorites')}
					className={ siteSelected === 'my-favorites' ?
					`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg pl-6 bg-opacity-50`
					:
					`ml-6 flex flex-row items-center gap-2 cursor-pointer h-[40px]`
					}
					>
						<BFIcon iconName="favorite" color="white" />
						<span className={colorStyle}>My Favorites</span>
						<span className="ml-auto w-[16px] h-[22px] rounded bg-main-buttonBlue text-sm flex items-center justify-center mr-2 font-DM_Sans font-medium leading-normal tracking-normal text-main-white">{favorites ? favorites.length: ''}</span>
					</Link>
			}

		</>}
		<Link to="/coins" onClick={() => setShowMobileMenu(false) & setSiteSelected('coins')}
		className={ siteSelected === 'coins' ?
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-[10px] bg-opacity-50`
			 :
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] ml-[10px]`
			}
		>
			<BFIcon iconName="coins" color={secondaryColor} />
			<span className={colorStyle}>Coins</span>
		</Link>
		<Link to="/Altcoin-Season-Index" onClick={() => setShowMobileMenu(false) & setSiteSelected('Altcoin-Season-Index')}
		className={ siteSelected === 'Altcoin-Season-Index' ?
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-[10px] bg-opacity-50`
			 :
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] ml-[10px]`
			}
		>
			<BFIcon iconName="indexes" color={secondaryColor} />
			<span className={colorStyle}>Altcoin Season Index</span>
		</Link>
		<Link onClick={() => setShowMobileMenu(false) & setSiteSelected('compare')}  to="/compare"
		className={ siteSelected === 'compare' ?
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-[10px] bg-opacity-50`
			 :
			`flex flex-row items-center gap-2 cursor-pointer h-[40px] ml-[10px]`
			}
		>
			<BFIcon iconName="compare" color={secondaryColor} />
			<span className={colorStyle}>Compare</span>
		</Link>
		<div className="flex flex-row items-center justify-between gap-2 cursor-pointer h-[40px] ml-[10px]" onClick={() => setDevtoolsExpanded(!devtoolsExpanded)}>
			<div className='flex flex-row items-start'>
				<div className='pr-2'>
					<BFIcon iconName="codebase" color={secondaryColor} />
				</div>
				<span className={colorStyle}>API</span>
			</div>
			<div className='text-[7px]'>
				{
					devtoolsExpanded == true ? <BFIcon iconName="arrowUP" color={whiteColor}/> : <BFIcon iconName="arrowDown" color={whiteColor}/>
				}
			</div>
		</div>
		{ devtoolsExpanded && <>
				<div className="cursor-pointer h-[40px]">
					<Link onClick={() => {setShowMobileMenu(false); setSiteSelected('api/documentation'); window.location.href = "https://www.bitformance.com/api/documentation";}}
					className={ siteSelected === 'api-docs' ?
					`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-6 bg-opacity-50`
					 :
					`ml-6 flex flex-row items-center gap-2 cursor-pointer h-[40px]`
					}
					>
						<BFIcon iconName="file-lines" color="white" />
						<span className={`${colorStyle}`}>API Documentation</span>
					</Link>
				</div>
				{ isLogin && <>
					<div className="cursor-pointer h-[40px]">
						<Link onClick={() => setShowMobileMenu(false) & setSiteSelected('API-Dashboard')}  to="/API-Dashboard"
						className={ siteSelected === 'home' ?
							`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-6 bg-opacity-50`
							 :
							`ml-6 flex flex-row items-center gap-2 cursor-pointer h-[40px]`
							}
						>
							<BFIcon iconName="network" color="white" />
							<span className={colorStyle}>API Dashboard</span>
						</Link>
					</div>
				</>}
				{ !isLogin &&
					<div className="cursor-pointer h-[40px]" onClick={() => {
						if (setShowMobileMenu) {
							setShowMobileMenu(false)
						}
						setShowModalType('SIGN_IN')
					}}  className={ siteSelected === 'API-Dashboard' ?
						`flex flex-row items-center gap-2 cursor-pointer h-[40px] bg-main-buttonBlue rounded-lg px-6 bg-opacity-50`
						 :
						`ml-6 flex flex-row items-center gap-2 cursor-pointer h-[40px]`
						}>
						<BFIcon iconName="network" color="white" />
						<span className={colorStyle}>API Dashboard</span>
					</div>
				}
		</>}
		<button onClick={() => {
			if (setShowMobileMenu) {
				setShowMobileMenu(false)
			}
			setShowModalType('CONTACT_US')
		}}
		className={`flex flex-row items-center gap-2 cursor-pointer h-[40px] ml-[10px]`}
		>
			<BFIcon iconName="contact-us" color={secondaryColor} />
			<span className={colorStyle}>Contact Us</span>
		</button>
		{ isLogin &&
		<Link reloadDocument to="/indexes/create-index" className={colorStyle}>
			<div onClick={() => {setShowMobileMenu(false); navigate('/indexes/create-index',{state: {mode: 'Create'}})}}  className="rounded-lg bg-main-buttonBlue text-center px-4 py-2 mt-2 w-full shadow-sm shadow-main-shadowBlue">
				+ Create Index
			</div>
		</Link> }
		{ !isLogin &&
			<div onClick={() => {
				if (setShowMobileMenu) {
					setShowMobileMenu(false)
				}
				setShowModalType('SIGN_IN')
			}}  className="rounded-lg bg-main-buttonBlue text-center cursor-pointer px-4 py-2 mt-2 w-full shadow-sm shadow-main-shadowBlue">
				+ Create Index
			</div>
		}
	</>)
}
