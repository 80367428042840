import React from 'react';
import { useNavigate } from 'react-router-dom'

const PaymentSuccess = () => {
  const navigate = useNavigate()

  return (
    <div className="min-h-screen flex items-center justify-center bg-green-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <svg className="w-16 h-16 text-green-500 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
        </svg>
        <h2 className="text-2xl font-semibold mb-2 text-green-700">Payment Successful!</h2>
        <p className="text-gray-700 mb-4">Thank you for your payment. Your transaction has been completed successfully.</p>
        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={() => {navigate('/api-dashboard')}}>
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
