import React from 'react';
import { useNavigate } from 'react-router-dom'

const PaymentFailed = () => {
  const navigate = useNavigate()

  return (
    <div className="min-h-screen flex items-center justify-center bg-red-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <svg className="w-16 h-16 text-red-500 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <h2 className="text-2xl font-semibold mb-2 text-red-700">Payment Failed!</h2>
        <p className="text-gray-700 mb-4">We're sorry, but your payment could not be processed. Please try again.</p>
        <button className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600" onClick={() => {navigate('/api-dashboard')}}>
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default PaymentFailed;
