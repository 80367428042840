import RecentlyAdded from '../components/RecentlyAdded'
import PerformersCard from '../components/PerformersCard'
import MostPopularCard from '../components/MostPopularCard'
import GraphCard from '../components/GraphCard'
import FooterMobile from '../components/small/FooterMobile'
import { useApiInfo, getNewApiKeys } from '../endpoints/index'
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import BFIcon from '../components/BFIcon'
import SubscriptionPlans from '../components/SubscriptionPlans'
import BFLoading from '../components/small/BFLoading';
// import { formatMoney, calculateReturn, calculateDrawdown } from '../helpers/index'

export default function APIDashboard({ setShowModalType, isAuth }) {

	const navigate = useNavigate()

	useEffect(() => {
      if (!localStorage.getItem('username')) {
        navigate('/?sessionExpired=true')
      }
    }, [])

	const [userApiData, setUserApiData] = useState([]);
	const [refresh, setRefresh] = useState(0);
	const [hasKey, setHasKey] = useState(true);
	const [tier,setTier] = useState("free")
	const [isLoading,setIsLoading] = useState(false)

	const { data, isValid } = useApiInfo();

	useEffect(() => {
		if (data) {
			if (data.length > 0) {
				setHasKey(true)
				data.forEach(d => {
					d.status = d.active ? 'Active' : 'Inactive'
					if (d.active === true) {
						setTier(d.tier)
					}
				})
			  setUserApiData(data)
		  } else if (isValid === true) {
		    // setHasKey(false)
		  }
		}
	}, [data,refresh,userApiData, isLoading]);

	const handleResetKeys = async () => {
		const newKeys = await getNewApiKeys("free")

		console.log(newKeys)
	}
	const handleGetNewKeys = async (tier,is_new_key) => {
		setIsLoading(true);
		try {
			// Your async function here
			const newKeys = await getNewApiKeys(tier,is_new_key)
			console.log(newKeys)
		} catch (error) {
			console.error("Error occurred:", error);
		} finally {
			setIsLoading(false); // End loading
			setShowModalType('CHECK_EMAIL_API')
			setHasKey(true)
		}
	}
	// const handleGetHeaders = async () => {
	// 	const newKeys = await fetchHeaders()
	// }

	function formatToUTC(expiration) {
	    // Create a Date object
	    const date = new Date(expiration);

	    // Format the date to 'year-month-day hour:minute' in UTC
	    const formattedDate = date.getUTCFullYear() + '-' +
	        String(date.getUTCMonth() + 1).padStart(2, '0') + '-' +
	        String(date.getUTCDate()).padStart(2, '0') + ', ' +
	        String(date.getUTCHours()).padStart(2, '0') + ':' +
	        String(date.getUTCMinutes()).padStart(2, '0');

	    return formattedDate;
	}

	if (isLoading) return <BFLoading />;

	return(
		<div className="p-0  space-y-4 relative sm:p-4">
		<style> {`

			  @media (max-width: 640px) {
				.table-auto thead {
				  display: none;
				}
				.table-auto tbody, .table-auto tr, .table-auto td {
				  display: block;
				  width: 100%;
				}
				.table-auto td::before {
				  content: attr(data-label);
				  font-weight: bold;
				  display: block;
				  margin-bottom: 0.5rem;
				  color: #333;
				}
				.table-auto td {
				  border-bottom: 1px solid #ddd;
				  padding-left: 1.5rem;
				  padding-bottom: 1rem;
				}
				.table-auto td:last-child {
	              border-bottom: none; /* Removes the last border */
	            }
			  }
		  `}
		</style>
		<div className="overflow-x-auto bg-main-white justify-between mt-3 border-[1px] border-main-lightGrayBorder sm:rounded-2xl p-4">
		  <div className="flex items-center space-x-4 mb-4">
			  <h2 className="text-lg font-semibold">USER API KEYS</h2>
			  <button onClick={() => {setRefresh(refresh+1)}}>
				  <BFIcon iconName="refresh" />
			  </button>
		  </div>
		  <table className="min-w-full text-sm text-left mb-8 table-auto">
			<thead className="table-header bg-gray-400 text-white uppercase text-sm leading-normal">
			  <tr>
				<th className="cursor-pointer py-1 px-1">
				  API-Key
				</th>
			  	<th className="cursor-pointer py-1 px-1" >
				  Tier
				</th>
			  	<th className="cursor-pointer py-1 px-1" >
				  Remaining Credits
				</th>
			  	<th className="cursor-pointer py-1 px-1" >
				  Renewal Date
				</th>
				<th className="cursor-pointer py-1 px-1">
				  Status
				</th>
				<th className="cursor-pointer py-1 px-1" >
				Get New Keys
				</th>
			  </tr>
			</thead>
			<tbody>
			  {userApiData.map((datapoint) => (
				  <React.Fragment key={datapoint.key}>
				  <tr className="">
				  	<td className="" data-label="API-Key">{datapoint['api-key']}</td>
					<td className="py-1 px-1" data-label="Tier">{datapoint.tier.charAt(0).toUpperCase() + datapoint.tier.slice(1)}</td>
					<td className="py-1 px-1" data-label="Remaining Credits">{datapoint.credits}</td>
					<td className="py-1 px-1" data-label="Renewal Date">{formatToUTC(datapoint.expiration)} UTC</td>
					<td className="py-1 px-1" data-label="Status">{datapoint.status}</td>
					<td className="py-1 px-1">
						<button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600" onClick={() => handleGetNewKeys(datapoint.tier,false)}>
							Regenerate API Key
						</button>
					</td>
				  </tr>
				  </React.Fragment>
			  ))}
			</tbody>
		  </table>
		<div>
			{!hasKey &&
				<button className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600" onClick={() => handleGetNewKeys("free",true)}>
					Activate FREE Key
				</button>
			}
		</div>
		<div>
			<SubscriptionPlans currentSubscription={tier} setShowModalType={setShowModalType}/>
		</div>
		</div>
			<div className='bg-white md:hidden'>
				<FooterMobile />
			</div>
		</div>
	)

}
