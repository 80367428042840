import { useState } from 'react'
import SignInForm from '../small/SignInForm'
import CloseModal from '../small/CloseModal'
import BitformanceLeftPanelHeader from '../small/BitformanceLeftPanelHeader';
import BitformanceLeftPanelFooter from '../small/BitformanceLeftPanelFooter';
import BitformanceLeftPanel from '../small/BitformanceLeftPanel';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function CheckEmailModal({ setShowModalType }) {

	const { width } = useWindowDimensions();

	return(
		<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
	      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
	        <svg className="w-16 h-16 text-green-500 mx-auto mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
	          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
	        </svg>
	        <h2 className="text-2xl font-semibold mb-2 text-green-700">New API Keys created!</h2>
	        <p className="text-gray-700 mb-4">Please check your email for your new API Keys</p>
	        <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" onClick={() => setShowModalType('')}>
	          Close
	        </button>
			<CloseModal setShowModalType={setShowModalType} topStyle="top-0" />
	      </div>
		</div>
	)
}
